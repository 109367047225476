import { useState } from "react";
import { Dispatch, SetStateAction } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControlLabel,
  Grid,
  Switch,
  InputAdornment,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { validatePassword } from "../../../utils/function";

const AccountForm = (props: ChildProps) => {
  const {
    type,
    setType,
    view,
    locked,
    setLocked,
    receiveSms,
    setReceiveSms,
    isActive,
    setIsActive,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    primaryPhone,
    setPrimaryPhone,
    userId,
    password,
    setPassword,
    error,
    setError,
  } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleValidation = () => {
    if (!validatePassword(password)) {
      setError("Passwords needs to have minimum 8 characters, at least one numerical character and one special character!");
    } else {
      setError("");
    }
  };

  return (
    <>
      <Grid item xs={4}>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            label="Question Type"
            onChange={(e: SelectChangeEvent<string>) => setType(e.target.value)}
          >
            <MenuItem value={"Manager"}>Manager</MenuItem>
            <MenuItem value={"Sales"}>Sales</MenuItem>
            <MenuItem value={"Admin"}>Admin</MenuItem>
            <MenuItem value={"Developer"}>Developer</MenuItem>
            <MenuItem value={"Realtor"}>Realtor</MenuItem>
            <MenuItem value={"Marketing"}>Marketing</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {view === "add" ? (
        <Grid item xs={8}></Grid>
      ) : (
        <Grid item xs={8}>
          <FormControlLabel
            checked={locked}
            control={<Switch onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLocked(!locked)} color="secondary" />}
            label="Locked"
          />
          <FormControlLabel
            checked={isActive}
            control={<Switch onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsActive(!isActive)} color="secondary" />}
            label="Active"
          />
          {type !== "Realtor" && type !== "Developer" ? (
            <FormControlLabel
              checked={receiveSms}
              control={<Switch onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReceiveSms(!receiveSms)} color="secondary" />}
              label="Receive Sms"
            />
          ) : null}
        </Grid>
      )}
      <Grid item xs={6}>
        <FormControl fullWidth variant="outlined">
          <TextField
            fullWidth
            required
            name="firstName"
            label="First Name"
            type="text"
            variant="outlined"
            value={firstName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth variant="outlined">
          <TextField
            fullWidth
            required
            name="lastName"
            label="Last Name"
            type="text"
            variant="outlined"
            value={lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth variant="outlined">
          <TextField
            fullWidth
            required
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth variant="outlined">
          <TextField
            fullWidth
            name="primaryPhone"
            label="Primary Phone"
            title="Primary Phone"
            type="number"
            variant="outlined"
            value={primaryPhone}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPrimaryPhone(e.target.value)}
          />
        </FormControl>
      </Grid>
      {type !== "Realtor" && !userId ? (
        <Grid item xs={6}>
          <FormControl fullWidth variant="outlined">
            <TextField
              fullWidth
              required
              name="password"
              label="Password"
              variant="outlined"
              value={password}
              type={showPassword ? "text" : "password"}
              onBlur={() => handleValidation()}
              error={error ? true : false}
              helperText={error ? error : ""}
              inputProps={{ minLength: 8 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword ? (
                      <VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => setShowPassword(!showPassword)} />
                    ) : (
                      <VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => setShowPassword(!showPassword)} />
                    )}
                  </InputAdornment>
                ),
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            />
          </FormControl>
        </Grid>
      ) : (
        <Grid item xs={6}></Grid>
      )}
    </>
  );
};

interface ChildProps {
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  view: string;
  locked: boolean;
  setLocked: Dispatch<SetStateAction<boolean>>;
  receiveSms: boolean;
  setReceiveSms: Dispatch<SetStateAction<boolean>>;
  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  primaryPhone: string;
  setPrimaryPhone: Dispatch<SetStateAction<string>>;
  userId: string;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
}

export default AccountForm;
