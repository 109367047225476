import { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  Box,
  Grid,
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { addSeconds, addHours, eachDayOfInterval, addDays } from "date-fns";
import Calendar from "react-calendar";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { ExpandMore } from "@mui/icons-material";
import ComputerIcon from "@mui/icons-material/Computer";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import { dateConflict, useGetAppointmentsQuery, withinTwelveHours } from "../../../features/appointments/appointmentHooks";
import { IAppointment } from "../../../types/project";
import { IUser } from "../../../types/user";
import { Container } from "../../../commonStyles";
import { ISchedule, IScheduleSetting } from "../../../types/schedule";
import { convertAllDates, timeZoneDate } from "../../../utils/function";
import { IProject } from "../../../types/project";
import "react-calendar/dist/Calendar.css";
import "./AppointmentCalendar.css";

const AppointmentCalendar = ({ user, setDate, setFormError, appointment, schedules, schedule, setSchedule, project }: AppointmentProps) => {
  const [day, setDay] = useState<Date>(appointment ? new Date(appointment.date) : new Date());
  const [changedMonth, setChangedMonth] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [dateWarning, setDateWarning] = useState<boolean>(false);
  const [dateError, setDateError] = useState<boolean>(false);
  const [dateFormatError, setDateFormatError] = useState<boolean>(false);
  const [timeSlots, setTimeSlots] = useState<Date[]>([]);
  const [timeSlot, setTimeSlot] = useState<Date | null>(null);
  const [time, setTime] = useState<Date | null | undefined>(appointment ? new Date(appointment.date) : null);

  let todaysAppointments = appointments
    .filter((appointment) => {
      const curDate = new Date(appointment.date);
      return curDate.toDateString() === day.toDateString();
    })
    .sort((a: any, b: any) => new Date(a.date).valueOf() - new Date(b.date).valueOf());

  const daysOfTheWeek = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
  const monthsOfTheYear = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."];

  const formatDateToDay = (date: Date) => {
    return `${daysOfTheWeek[date.getDay()]} ${monthsOfTheYear[date.getMonth()]} ${date.getDate()}`;
  };

  const onCompletedAppointments = (data: any) => {
    setAppointments(data);
  };

  const [, { refetch: refetchAppointments }] = useGetAppointmentsQuery(user?._id, day);

  const [getCalendar, { loading }] = useLazyQuery(GETCALENDAR, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const mergeDayAndTime = (time: Date | null | undefined, day: Date) => {
    if (!time) return null;
    const dateTime = new Date(time);
    return new Date(day.getFullYear(), day.getMonth(), day.getDate(), dateTime.getHours(), dateTime.getMinutes());
  };

  useEffect(() => {
    const get = async () => {
      const res = await refetchAppointments();
      let filteredEvents: any[] = [];
      if (user) {
        await getCalendar({ variables: { user: user?._id } }).then((res) => {
          if (res.data) {
            filteredEvents = res.data.getCalendar[0].events
              .filter(
                (event: any) =>
                  event.start && ((event.organizer && event.organizer.email === user?.email) || event.visibility === "private")
              )
              .map((event: any) => {
                if (event.start.date && event.end.date) {
                  let dates = [new Date(event.start.date)];
                  if (new Date(addDays(new Date(event.start.date.set), 1)) === new Date(event.end.date)) {
                    dates = eachDayOfInterval({
                      start: new Date(addDays(new Date(event.start.date.set), 1)),
                      end: new Date(event.end.date),
                    });
                  }
                  let daysOff = dates.map((date: Date) => {
                    return {
                      date: addHours(date, 5),
                      location: event.location ? event.location : "Busy",
                      project: {
                        name: event.start.date ? "All Day" : "Busy",
                      },
                      purchaserInfo: {
                        firstName: "Busy",
                        lastName: "",
                      },
                      registrant: null,
                      virtual: false,
                    };
                  });
                  return daysOff;
                } else {
                  return {
                    date: event.start.dateTime ? event.start.dateTime : addHours(new Date(event.start.date), 5),
                    endDate: event.end.dateTime ? event.end.dateTime : addHours(new Date(event.end.date), 5),
                    location: event.location ? event.location : "Busy",
                    project: {
                      name: event.start.date ? "All Day" : "Busy",
                    },
                    purchaserInfo: {
                      firstName: "Busy",
                      lastName: "",
                    },
                    registrant: null,
                    virtual: false,
                  };
                }
              });
          }
        });
      }
      onCompletedAppointments([
        ...res.data.appointmentMany.filter((appointment: IAppointment) => !appointment.cancelled),
        ...filteredEvents.flat(),
      ]);
    };
    get();
  }, [refetchAppointments, user, getCalendar, changedMonth]);

  useEffect(() => {
    handleSchedule(schedule?._id!);
  }, [schedule, schedules]);

  const handleSchedule = (value: string) => {
    let selectedSchedule = schedules.find((schedule: ISchedule) => schedule._id === value);
    if (selectedSchedule) {
      let timeslots: any[] = selectedSchedule.schedules.map((schedule: IScheduleSetting) => {
        let allSlots = [];
        for (let i = schedule.timeStart; i <= schedule.timeEnd - schedule.length * 60; i += schedule.timeBetweenAppointments * 60) {
          let scheduleDate = new Date(schedule.date!).setHours(0, 0, 0, 0);
          if (new Date(addSeconds(scheduleDate, i)).valueOf() > new Date().valueOf()) {
            allSlots.push(new Date(addSeconds(scheduleDate, i)));
          }
        }
        return allSlots;
      });
      setTimeSlots(timeslots.flat());
      setSchedule(selectedSchedule);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={user ? 6 : 12}>
        <Calendar
          className="calendar"
          value={day || new Date()}
          minDate={new Date()}
          onActiveStartDateChange={() => {
            setChangedMonth(true);
          }}
          onChange={async (newDay: Date) => {
            setChangedMonth(false);
            setDay(newDay);
          }}
        />
      </Grid>
      {user ? (
        <Grid item xs={12} sm={6}>
          <Container>
            <Typography>{user?.firstName}'s schedule on</Typography>
            <Typography sx={{ mb: 1 }}>{formatDateToDay(day)}:</Typography>
            {loading ? (
              <>
                <Skeleton height={25} animation="wave" />
                <Skeleton height={25} animation="wave" />
                <Skeleton height={25} animation="wave" />
                <Skeleton height={25} animation="wave" />
              </>
            ) : todaysAppointments.length > 0 ? (
              <Stack spacing={1}>
                {todaysAppointments.map((curAppointment, key) => {
                  const curDate = new Date(curAppointment.date);
                  let endDate = null;
                  if (curAppointment.endDate) {
                    endDate = new Date(curAppointment.endDate);
                  }
                  return (
                    <Accordion key={key} sx={{ border: "1px solid black", borderRadius: "5px" }}>
                      <AccordionSummary sx={{ borderBottom: "1px solid black" }} expandIcon={<ExpandMore />}>
                        <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", fontSize: { xs: 12, sm: 15 } }}>
                          <strong>
                            {curAppointment.project?.name === "Busy"
                              ? `${convertAllDates(curDate, "pp")} ${curAppointment.endDate ? `- ${convertAllDates(endDate, "pp")}` : null}`
                              : curAppointment.project?.name === "All Day"
                              ? "All Day"
                              : convertAllDates(timeZoneDate(curDate), "pp")}{" "}
                            - {curAppointment?.project?.name}
                          </strong>
                          {curAppointment?.virtual && <ComputerIcon />}
                          {appointment?._id && appointment?._id === curAppointment?._id && <RadioButtonCheckedIcon />}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack spacing={1}>
                          <Typography>
                            <strong>Client:</strong> {curAppointment.purchaserInfo.firstName} {curAppointment.purchaserInfo.lastName}
                          </Typography>
                          <Typography>
                            <strong>Location:</strong> {curAppointment.virtual ? "Virtual" : curAppointment.location}
                          </Typography>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Stack>
            ) : (
              <Typography>
                <strong>None</strong>
              </Typography>
            )}
          </Container>
        </Grid>
      ) : null}
      {schedules.length ? (
        <Grid item xs={12}>
          <Box sx={{ mt: 2 }}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Events</InputLabel>
              <Select
                label="Events"
                sx={{ width: "100%" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={schedule ? schedule._id : ""}
                onChange={(e: any) => {
                  handleSchedule(e.target.value);
                }}
              >
                {schedules
                  .filter((schedule: ISchedule) => schedule.project._id === project?._id)
                  .map((schedule: ISchedule, index: number) => {
                    return (
                      <MenuItem key={index} value={schedule._id}>
                        {schedule.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      ) : null}
      {schedule ? (
        <Grid item xs={12}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="timeslot-label">Time Slot</InputLabel>
            <Select
              labelId="timeslot-label"
              value={timeSlot}
              label="Time Slot"
              onChange={(e) => {
                setDay(new Date(e.target.value!));
                setTimeSlot(new Date(e.target.value!));
                setTime(new Date(e.target.value!));
                setDate(new Date(e.target.value!));
              }}
            >
              {timeSlots.map((timeSlot: Date, index: number) => {
                return (
                  <MenuItem key={index} value={timeSlot.toString()}>
                    {" "}
                    {convertAllDates(timeSlot, "PPpp")}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {
            <TimePicker
              label={appointment ? "Appointment Time" : "New Appointment Time"}
              value={time}
              onChange={(newTime) => {
                if (!newTime || !Date.parse(newTime.toString())) {
                  setDateFormatError(true);
                  return;
                }
                setDateFormatError(false);
                setTime(newTime);
                setDate(mergeDayAndTime(newTime, day));
                if (withinTwelveHours(mergeDayAndTime(newTime, day))) {
                  setDateWarning(true);
                } else {
                  setDateWarning(false);
                }
                if (dateConflict(mergeDayAndTime(newTime, day), appointments, appointment)) {
                  setDateError(true);
                } else {
                  setDateError(false);
                }
              }}
              renderInput={(params) => <TextField {...params} fullWidth required={!time} />}
            />
          }
          {dateWarning && (
            <Typography sx={{ fontSize: 10, color: "#c69035" }}>The time chosen is within 12 hours of the current moment.</Typography>
          )}
          {dateError && (
            <Typography sx={{ fontSize: 10, color: "#c69035" }}>This time conflicts with another of this user's appointment.</Typography>
          )}
          {dateFormatError && <Typography sx={{ fontSize: 10, color: "red" }}>This is an incorrectly formatted date.</Typography>}
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

type AppointmentProps = {
  user: IUser | undefined;
  setDate: (date: Date | null | undefined) => void;
  setFormError: (error: boolean) => void;
  appointment?: IAppointment;
  schedules: ISchedule[];
  schedule: ISchedule | null;
  setSchedule: any;
  project: IProject | null;
};

const GETCALENDAR = gql`
  query getCalendar($user: MongoID) {
    getCalendar(user: $user) {
      id
      name
      events {
        id
        status
        location
        htmlLink
        summary
        created
        updated
        creator {
          email
        }
        organizer {
          email
          displayName
          self
        }
        start {
          date
          dateTime
          timeZone
        }
        end {
          date
          dateTime
          timeZone
        }
        attendees {
          email
          organizer
          self
          responseStatus
        }
        hangoutLink
        visibility
      }
    }
  }
`;

export default AppointmentCalendar;
