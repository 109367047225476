import { SetStateAction, Dispatch } from "react";
import { TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";

const TextFilter = (props: ChildProps) => {
  const user = useSelector(selectUser);
  const { projects, setProjects, setPageNumber, userType, setUserType, lastReply, setLastReply, messageType, setMessageType } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Autocomplete
          multiple
          options={[user?.projectAccess!.map((projectAccess: any) => projectAccess?.project.name!)].flat()}
          freeSolo={false}
          value={projects}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option: any) => option}
          onChange={(e, value) => {
            setPageNumber(0);
            setProjects(value);
          }}
          renderInput={(params) => <TextField {...params} label="Projects" />}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <InputLabel id="user-type">User Type</InputLabel>
          <Select
            labelId="user-type"
            id="userType"
            name={"answerType"}
            label={"Answer Type"}
            value={userType ? userType : "All"}
            onChange={(e) => setUserType(e.target.value)}
          >
            <MenuItem value={user?._id}>{user?.fullName}</MenuItem>
            <MenuItem value={"All"}>All</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <InputLabel id="last-reply">Last Reply</InputLabel>
          <Select
            labelId="last-reply"
            id="lastReply"
            name={"lastReply"}
            label={"Last Reply"}
            value={lastReply}
            onChange={(e) => setLastReply(e.target.value)}
          >
            <MenuItem value={"Registrant"}>Registrant</MenuItem>
            <MenuItem value={"Automated"}>Automated</MenuItem>
            <MenuItem value={"User"}>{user?.fullName}</MenuItem>
            <MenuItem value={"Automated Registrant"}>Automated and Registrant</MenuItem>
            <MenuItem value={"All"}>All</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <InputLabel id="message-type">Message Type</InputLabel>
          <Select
            labelId="message-type"
            id="messageType"
            name={"messageType"}
            label={"Message Type"}
            value={messageType}
            onChange={(e) => setMessageType(e.target.value)}
          >
            <MenuItem value={"Unarchived"}>Unarchived</MenuItem>
            <MenuItem value={"Archived"}>Archived</MenuItem>
            <MenuItem value={"All"}>All</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

interface ChildProps {
  projects: any[];
  setProjects: any;
  setPageNumber: Dispatch<SetStateAction<number>>;
  userType: any;
  setUserType: any;
  lastReply: any;
  setLastReply: any;
  messageType: any;
  setMessageType: any;
}

export default TextFilter;
