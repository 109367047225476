/* Dependency Imports */
import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { useLocation } from "react-router";

/* Project Imports */
import BasicTabs from "../common/BasicTabs";
import General from "./General";
import Processes from "./Processes";
import Questionaire from "./Questionaire";
import CreateRegistrant from "./CreateRegistrant";
import RealtorTypes from "./RealtorTypes";
import Campaign from "./campaign/Campaign";
import Sms from "./sms/Sms";
import { selectProject } from "../../features/project/projectSlice";
import { FlexBetween } from "../../commonStyles";
import { SettingContainer } from "../../commonStyles";
import { selectProjectQuestions } from "../../features/projectSetting/projectSettingSlice";
import { selectUser } from "../../features/auth/authSlice";

const ProjectSettings = () => {
  const user = useSelector(selectUser);
  const location: any = useLocation();

  const project = useSelector(selectProject);
  const questions = useSelector(selectProjectQuestions);
  const [value, setValue] = useState(location.state?.tab || 0);

  const tabs = useMemo(() => {
    let settingsTab = [
      {
        label: "General",
        component: <General project={project} />,
      },
      {
        label: "Processes",
        component: <Processes project={project} />,
      },
      {
        label: "Questionnaire",
        component: <Questionaire project={project} />,
      },
      {
        label: "Create Registrant",
        component: (
          <SettingContainer>
            <CreateRegistrant project={project} questions={questions} />
          </SettingContainer>
        ),
      },
      {
        label: "Realtor Types",
        component: <RealtorTypes project={project} />,
      },
    ];

    if (user?.type === "Manager") {
      settingsTab.push({
        label: "Campaigns",
        component: <Campaign project={project} />,
      });
      settingsTab.push({
        label: "Registrant Auto SMS",
        component: <Sms project={project} />,
      });
    }

    return settingsTab;
  }, [user, project]);

  return (
    <div>
      <FlexBetween sx={{ mb: 2 }}>
        <Typography variant="h2" component="div" gutterBottom>
          <strong>{project?.name} Settings</strong>
        </Typography>
      </FlexBetween>
      <BasicTabs color="#00142a" tabs={tabs} value={value} setValue={setValue} />
    </div>
  );
};

/* Types */

/* GQL */

export default ProjectSettings;
