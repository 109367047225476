import { useEffect, useState } from "react";
import { useLazyQuery, gql, useMutation } from "@apollo/client";
import { List, ListItem, ListItemText, Typography, IconButton, Box, Tooltip, Modal, Backdrop } from "@mui/material";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { useSelector } from "react-redux";

import { Container, FlexBetween } from "../../../commonStyles";
import { useNavigate } from "react-router";
import { useListsQuery } from "../../../features/projectSetting/projectSettingHooks";
import { IList } from "../../../features/projectSetting/projectSettingSlice";
import { selectUser } from "../../../features/auth/authSlice";

const Lists = (props: ChildProps) => {
  const { projectId, custom } = props;
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  useListsQuery(projectId!, user!);

  const [open, setOpen] = useState<boolean>(false);
  const [lists, setLists] = useState<IList[]>([]);
  const [archivedLists, setArchivedLists] = useState<IList[]>([]);

  const [getLists] = useLazyQuery(GETLISTS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (open) {
        setArchivedLists(data.listMany);
      } else {
        setLists(data.listMany);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [archiveList] = useMutation(ARCHIVELIST, {
    onCompleted: (data) => {
      if (open) {
        setLists([...lists, data.listUpdateById.record]);
        setArchivedLists(archivedLists.filter((list: IList) => list._id !== data.listUpdateById.record._id));
      } else {
        setArchivedLists([...archivedLists, data.listUpdateById.record]);
        setLists(lists.filter((list: IList) => list._id !== data.listUpdateById.record._id));
      }
      setOpen(false);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    let query = { project: projectId, OR: [{ view: "Sales" }, { view: "All" }, { view: "Admin" }] };

    if (user?.type === "Sales") {
      query = { project: projectId, OR: [{ view: "Sales" }, { view: "All" }] };
    }
    if (user?.type === "Admin") {
      query = { project: projectId, OR: [{ view: "Admin" }, { view: "All" }] };
    }

    if (open) {
      getLists({ variables: { filter: { ...query, archived: true } } });
    } else {
      getLists({ variables: { filter: { ...query, archived: false } } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, projectId]);

  return (
    <Container sx={{ height: "100%" }}>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: 300,
              sm: 500,
              md: 700,
              lg: 1100,
              xl: 1400,
            },
            maxHeight: {
              xs: 300,
              sm: 600,
              md: 600,
              lg: 650,
              xl: 850,
            },
            overflowY: "auto",
            backgroundColor: "#fff",
            padding: "20px",
            border: "2px solid #000",
          }}
        >
          <Typography variant="h2">
            <strong>Archived List</strong>
          </Typography>
          <Box sx={{ mt: 1 }}>Please select a list you would like to unarchive.</Box>
          <List>
            {archivedLists.map((list: IList, index: number) => {
              return (
                <ListItem
                  key={index}
                  sx={{ p: 0 }}
                  secondaryAction={
                    custom ? (
                      <>
                        <Tooltip title="Archive List">
                          <IconButton
                            onClick={() => archiveList({ variables: { _id: list._id, record: { archived: false } } })}
                            sx={{ p: 0 }}
                            edge="end"
                            aria-label="archive"
                          >
                            <ArchiveIcon sx={{ color: "info.main" }} />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : null
                  }
                >
                  <ListItemText
                    primaryTypographyProps={{
                      pr: 8,
                    }}
                  >
                    <strong
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/dashboard/project/${projectId}/list/${list._id}?row=15&page=1`)}
                    >
                      {list.name}
                    </strong>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Modal>
      <Typography variant="h2" component="div" gutterBottom>
        <FlexBetween>
          <strong>{custom ? "Custom Lists" : "Prebuilt Lists"}</strong>
          <Box>
            {custom ? (
              <Tooltip title="Create Custom List">
                <LocalHospitalIcon
                  sx={{ cursor: "pointer", mr: 1 }}
                  onClick={() => navigate(`/dashboard/project/${projectId}/create-list`)}
                />
              </Tooltip>
            ) : null}
            {custom ? (
              <Tooltip title="Unarchive List">
                <UnarchiveIcon
                  sx={{ cursor: "pointer", mr: 0.5 }}
                  onClick={() => {
                    setOpen(true);
                  }}
                />
              </Tooltip>
            ) : null}
          </Box>
        </FlexBetween>
      </Typography>
      {lists.length ? (
        <List>
          {lists.map((list: IList, index: number) => {
            return (
              <ListItem
                key={index}
                sx={{ p: 0 }}
                secondaryAction={
                  custom ? (
                    <>
                      <Tooltip title="Edit List">
                        <IconButton
                          onClick={() => navigate(`/dashboard/project/${projectId}/edit-list/${list._id}`)}
                          sx={{ p: 0, mr: 1 }}
                          edge="end"
                          aria-label="edit"
                        >
                          <EditIcon sx={{ color: "info.main" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Archive List">
                        <IconButton
                          onClick={() => archiveList({ variables: { _id: list._id, record: { archived: true } } })}
                          sx={{ p: 0 }}
                          edge="end"
                          aria-label="archive"
                        >
                          <ArchiveIcon sx={{ color: "info.main" }} />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : null
                }
              >
                <ListItemText
                  primaryTypographyProps={{
                    pr: 8,
                  }}
                >
                  <strong
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/dashboard/project/${projectId}/list/${list._id}?row=15&page=1`)}
                  >
                    {list.name}
                  </strong>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <div>
          There are currently no custom lists. Please click on <strong>+</strong> to add one.
        </div>
      )}
    </Container>
  );
};

interface ChildProps {
  projectId?: string;
  custom: boolean;
}

const GETLISTS = gql`
  query listMany($filter: FilterFindManyListInput) {
    listMany(filter: $filter) {
      _id
      name
      archived
    }
  }
`;

const ARCHIVELIST = gql`
  mutation listUpdateById($_id: MongoID!, $record: UpdateByIdListInput!) {
    listUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        archived
      }
    }
  }
`;

export default Lists;
