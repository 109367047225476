import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Grid, Autocomplete, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useLazyQuery, gql } from "@apollo/client";
import { IAppointment, IProject } from "../../types/project";
import { selectUser } from "../../features/auth/authSlice";
import StandardTable from "../tables/StandardTable";
import { convertAllDates, timeZoneDate } from "../../utils/function";
import { useNavigate } from "react-router";

const Leasing = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [count, setCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [projects, setProjects] = useState<IProject[]>([]);

  const [getAppointments, { loading: appointmentsLoading }] = useLazyQuery(GETAPPOINTMENTS, {
    onCompleted: (data) => {
      setAppointments(data.appointmentPagination.items);
      setCount(data.appointmentPagination.count);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  useEffect(() => {
    getAppointments({
      variables: {
        projectIds: projects.length
          ? projects.map((project: IProject) => project._id)
          : user?.projectAccess.map((project: any) => project.project._id),
        perPage: 20,
        page: pageNumber + 1,
        type: "lease",
        sort: "DATE_DESC",
      },
    });
  }, [pageNumber, projects]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: (rowData: IAppointment) => {
          return (
            <Box
              sx={{ fontWeight: rowData.registrant ? 700 : 500, cursor: rowData.registrant ? "pointer" : "auto" }}
              onClick={() =>
                rowData.registrant ? navigate(`/dashboard/project/${rowData.project?._id}/registrant/${rowData.registrant._id}`) : null
              }
            >
              {rowData.purchaserInfo.firstName} {rowData.purchaserInfo.lastName}
            </Box>
          );
        },
      },
      {
        Header: "Appointment Date",
        accessor: (rowData: IAppointment) => convertAllDates(timeZoneDate(rowData.date), "PPpp"),
      },
      {
        Header: "Project",
        accessor: (rowData: IAppointment) => (rowData.project ? rowData.project?.name : ""),
      },
      {
        Header: "Location",
        accessor: (rowData: IAppointment) => rowData.location,
      },
      {
        Header: "Notes",
        accessor: (rowData: IAppointment) => rowData.notes,
      },
      {
        Header: "Contact Info",
        accessor: (rowData: IAppointment) => {
          return (
            <Box>
              {rowData.purchaserInfo.email} ({rowData.purchaserInfo.primaryPhone})
            </Box>
          );
        },
      },
      {
        Header: "Confirmed",
        accessor: (rowData: IAppointment) =>
          rowData.confirmed ? <CheckIcon sx={{ color: "green" }} /> : <CloseIcon sx={{ color: "red" }} />,
      },
      {
        Header: "Cancelled",
        accessor: (rowData: IAppointment) =>
          rowData.cancelled ? <CheckIcon sx={{ color: "green" }} /> : <CloseIcon sx={{ color: "red" }} />,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointments]);

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            multiple
            options={[user?.projectAccess!.map((projectAccess: any) => projectAccess?.project!)].flat()}
            freeSolo={false}
            value={projects}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option: any) => option.name}
            onChange={(e, value) => {
              setPageNumber(0);
              setProjects(value);
            }}
            renderInput={(params) => <TextField {...params} label="Projects" />}
          />
        </Grid>
      </Grid>
      <StandardTable columns={columns} data={appointments} loading={appointmentsLoading} />
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Button disabled={pageNumber === 0} onClick={() => setPageNumber(pageNumber - 1)}>
          {"<"}
        </Button>
        <span>{pageNumber + 1}</span>
        <Button disabled={pageNumber + 1 === Math.ceil(count / 20)} onClick={() => setPageNumber(pageNumber + 1)}>
          {">"}
        </Button>
      </Box>
    </Box>
  );
};

const GETAPPOINTMENTS = gql`
  query getFilteredAppointments(
    $page: Int
    $perPage: Int
    $projectIds: [MongoID!]
    $type: EnumAppointmentType
    $sort: SortFindManyAppointmentInput
  ) {
    appointmentPagination(page: $page, perPage: $perPage, filter: { projectIds: $projectIds, type: $type }, sort: $sort) {
      count
      items {
        _id
        project {
          _id
          name
        }
        user {
          firstName
          lastName
        }
        date
        location
        registrant {
          _id
        }
        purchaserInfo {
          firstName
          lastName
          email
          primaryPhone
          numberOfGuests
        }
        status
        confirmed
        cancelled
        salesRep {
          _id
          firstName
          lastName
          fullName
          email
        }
        type
        notes
      }
    }
  }
`;

export default Leasing;
