import React from "react";
import { Typography, Box, Grid } from "@mui/material";

const DataCard = (props: ChildProps) => {
  const { data } = props;
  return (
    <Box
      sx={{
        fontSize: "1rem",
        padding: "0.5rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #000",
        height: "100%",
      }}
    >
      <Box sx={{ fontSize: "24px" }}>{data.value}</Box>
      <Box sx={{ fontSize: "10px" }}>{data.title}</Box>
    </Box>
  );
};

interface ChildProps {
  data: any;
}

export default DataCard;
