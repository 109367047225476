import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { SettingContainer } from "../../commonStyles";
import BasicTabs from "../common/BasicTabs";
import Events from "./Events";
import Other from "./Other";
import Stats from "./Stats";
import Leasing from "./Leasing";
import Appointments from "./Search";
import { selectUser } from "../../features/auth/authSlice";

const Schedule = () => {
  const user = useSelector(selectUser);
  const [value, setValue] = useState(0);
  const tabs = useMemo(() => {
    let tabOptions = [
      {
        label: "All",
        component: <Stats />,
      },
      {
        label: "Event",
        component: <Events />,
      },
      {
        label: "Non Event",
        component: <Other />,
      },
      {
        label: "Search",
        component: <Appointments />,
      },
    ];

    if (user?.type !== "Developer") {
      let leasing = {
        label: "Leasing/Assignments",
        component: <Leasing />,
      };

      tabOptions.splice(3, 0, leasing);
    }

    return tabOptions;
  }, [user]);

  return (
    <SettingContainer sx={{ p: 0 }}>
      <Box>
        <BasicTabs color="#00142a" tabs={tabs} value={value} setValue={setValue} />
      </Box>
    </SettingContainer>
  );
};

export default Schedule;
