import { useState, useMemo } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";
import { useQuery, gql } from "@apollo/client";
import { Container } from "../../../commonStyles";
import { FlexBetween, FlexEnd } from "../../../commonStyles";
import StandardTable from "../../tables/StandardTable";
import { normalToHyphen } from "../../../utils/function";

const Prebuilt = (props: ChildProps) => {
  const { projectId } = props;
  const navigate = useNavigate();
  const [counts, setCounts] = useState<ICount[]>([]);

  const { loading } = useQuery(GETREGISTRANTCOUNTS, {
    variables: { project: projectId },
    onCompleted: (data) => {
      setCounts(data.getRegistrantCounts);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const columns = useMemo(() => {
    const handleRedirect = (name: string, type: string) => {
      navigate(`/dashboard/project/${projectId}/list/${`${normalToHyphen(name)}-${type}?row=15&page=1`}`);
    };

    return [
      {
        Header: "Name",
        accessor: (rowData: any) => rowData.name,
      },
      {
        Header: "Today",
        accessor: (rowData: any) => (
          <Box onClick={() => handleRedirect(rowData.name, "daily")} sx={{ cursor: "pointer" }}>
            <strong>{rowData.daily}</strong>
          </Box>
        ),
      },
      {
        Header: "Last 7 Days",
        accessor: (rowData: any) => (
          <Box onClick={() => handleRedirect(rowData.name, "weekly")} sx={{ cursor: "pointer" }}>
            <strong>{rowData.weekly}</strong>
          </Box>
        ),
      },
      {
        Header: "Last 30 Days",
        accessor: (rowData: any) => (
          <Box onClick={() => handleRedirect(rowData.name, "monthly")} sx={{ cursor: "pointer" }}>
            <strong>{rowData.monthly}</strong>
          </Box>
        ),
      },
      {
        Header: "All Time",
        accessor: (rowData: any) => (
          <Box onClick={() => handleRedirect(rowData.name, "all")} sx={{ cursor: "pointer" }}>
            <strong>{rowData.all}</strong>
          </Box>
        ),
      },
    ];
  }, [navigate, projectId]);

  return (
    <Container>
      <Typography variant="h2" component="div" gutterBottom>
        <FlexBetween>
          <strong>Prebuilt Lists</strong>
        </FlexBetween>
        <Box sx={{ mt: 2 }}>
          <StandardTable data={counts} columns={columns} loading={loading} width={true} />
        </Box>
        <FlexEnd sx={{ mt: 2 }}>
          <Button
            sx={{ fontSize: "11px", mr: 1 }}
            component={Link}
            to={`/dashboard/project/${projectId}/list/registrants-attended-rsvps?row=15&page=1`}
            color="primary"
            variant="contained"
          >
            Attended RSVPs
          </Button>
          <Button
            sx={{ fontSize: "11px", mr: 1 }}
            component={Link}
            to={`/dashboard/project/${projectId}/list/registrants-nonattended-rsvps?row=15&page=1`}
            color="primary"
            variant="contained"
          >
            Non Attended RSVPs
          </Button>
          <Button
            sx={{ fontSize: "11px", mr: 1 }}
            component={Link}
            to={`/dashboard/project/${projectId}/list/registrants-no-purchasers?row=15&page=1`}
            color="primary"
            variant="contained"
          >
            Non Purchasers
          </Button>
          <Button
            sx={{ fontSize: "11px" }}
            component={Link}
            to={`/dashboard/project/${projectId}/list/purchasers?row=15&page=1`}
            color="primary"
            variant="contained"
          >
            Purchasers
          </Button>
        </FlexEnd>
      </Typography>
    </Container>
  );
};

interface ChildProps {
  projectId?: string;
}

interface ICount {
  name: string;
  daily: number;
  weekly: number;
  monthly: number;
  all: number;
}

const GETREGISTRANTCOUNTS = gql`
  query getRegistrantCounts($project: MongoID) {
    getRegistrantCounts(project: $project) {
      name
      daily
      weekly
      monthly
      all
    }
  }
`;

export default Prebuilt;
