import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { FlexBetween } from "../../commonStyles";
import { handleModal } from "../../features/modal/modalSlice";
import { useAppDispatch } from "../../app/hooks";
import { IText, ITextMessage } from "../../types/callRail";

const Archive = (props: ChildProps) => {
  const { data, archiveTexts } = props;
  const storeDispatch = useAppDispatch();

  let archived = data?.messages.every((message: ITextMessage) => message.archived);
  return (
    <div>
      <Box>
        <Typography variant="h2">
          <strong>{archived ? "Unarchive" : "Archive"} Conversation?</strong>
        </Typography>
      </Box>
      <Box sx={{ my: 2 }}>Are you sure you would like to {archived ? "unarchive" : "archive"} this conversation?</Box>
      <FlexBetween>
        <Button variant="contained" color="info" onClick={() => storeDispatch(handleModal(false))}>
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={() => archiveTexts(archived)}>
          {archived ? "unarchive" : "archive"}
        </Button>
      </FlexBetween>
    </div>
  );
};

interface ChildProps {
  data: IText | null;
  archiveTexts: any;
}

export default Archive;
