import React, { useEffect, useState } from "react";
import { SettingContainer } from "../../commonStyles";
import { Typography, Button, Box, TextField, Autocomplete, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ProjectInfo from "../common/ProjectInfo";

import { IProject, IAddress } from "../../types/project";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useAppDispatch } from "../../app/hooks";
import { showSuccessSnackbar, showErrorSnackbar } from "../../features/snackbar/snackbarSlice";
import { setProject } from "../../features/project/projectSlice";
import { IUser } from "../../types/user";
import { useSelector } from "react-redux";
import {
  selectCrmProjectId,
  selectProjectTags,
  selectShortTermUsers,
  selectShortTermRealtorWalkIn,
  selectShortTermNoRealtorWalkIn,
  selectShortTermHasRealtorWalkIn,
  setShortTermUsers as updateShortTermUsers,
  setShortTermRealtorWalkIn as updateShortTermRealtorWalkIn,
  setShortTermNoRealtorWalkIn as updateShortTermNoRealtorWalkIn,
  setShortTermHasRealtorWalkIn as updateShortTermHasRealtorWalkIn,
  setSales as updateSales,
  setOnline as updateOnline,
  setScrum as updateScrum,
  setAdmins as updateAdmins,
  setTags as updateTags,
  setSmsDuringHours as updateSmsDuringHours,
  setSmsBeforeHours as updateSmsBeforeHours,
  setSmsAfterHours as updateSmsAfterHours,
  selectSales,
  selectOnline,
  selectScrum,
  selectAdmins,
  selectSmsBeforeHours,
  selectSmsDuringHours,
  selectSmsAfterHours,
} from "../../features/projectSetting/projectSettingSlice";

const General = (props: ChildProps) => {
  const { project } = props;
  const projectId = project?._id;
  const crmProjectId = useSelector(selectCrmProjectId);
  const crmProjectTags = useSelector(selectProjectTags);
  const projectShortTermUsers = useSelector(selectShortTermUsers);
  const projectShortTermRealtorWalkIn = useSelector(selectShortTermRealtorWalkIn);
  const projectShortTermNoRealtorWalkIn = useSelector(selectShortTermNoRealtorWalkIn);
  const projectShortTermHasRealtorWalkIn = useSelector(selectShortTermHasRealtorWalkIn);
  const projectSales = useSelector(selectSales);
  const projectOnline = useSelector(selectOnline);
  const projectScrum = useSelector(selectScrum);
  const projectAdmins = useSelector(selectAdmins);
  const projectSmsBeforeHours = useSelector(selectSmsBeforeHours);
  const projectSmsDuringHours = useSelector(selectSmsDuringHours);
  const projectSmsAfterHours = useSelector(selectSmsAfterHours);

  const storeDispatch = useAppDispatch();

  const [projectName, setProjectName] = useState<string | undefined>(project?.name);
  const [developerName, setDeveloperName] = useState<string | undefined>(project?.developerName);
  const [addresses, setAddresses] = useState(project?.addresses ? project.addresses : []);
  const [email, setEmail] = useState<string | undefined>(project?.email);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [users, setUsers] = useState<IUser[]>([]);
  const [shortTermUsers, setShortTermUsers] = useState<IUser[]>([]);
  const [shortTermRealtorWalkIn, setShortTermRealtorWalkIn] = useState<IUser[]>([]);
  const [shortTermNoRealtorWalkIn, setShortTermNoRealtorWalkIn] = useState<IUser[]>([]);
  const [shortTermHasRealtorWalkIn, setShortTermHasRealtorWalkIn] = useState<IUser[]>([]);
  const [smsBeforeHours, setSmsBeforeHours] = useState<IUser[]>([]);
  const [smsDuringHours, setSmsDuringHours] = useState<IUser[]>([]);
  const [smsAfterHours, setSmsAfterHours] = useState<IUser[]>([]);
  const [sales, setSales] = useState<IUser[]>([]);
  const [online, setOnline] = useState<IUser[]>([]);
  const [scrum, setScrum] = useState<IUser[]>([]);
  const [admins, setAdmins] = useState<IUser[]>([]);
  const [tagValue, setTagValue] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    setShortTermUsers(projectShortTermUsers);
    setShortTermRealtorWalkIn(projectShortTermRealtorWalkIn);
    setShortTermNoRealtorWalkIn(projectShortTermNoRealtorWalkIn);
    setShortTermHasRealtorWalkIn(projectShortTermHasRealtorWalkIn);
    setSales(projectSales);
    setOnline(projectOnline);
    setScrum(projectScrum);
    setAdmins(projectAdmins);
    setSmsBeforeHours(projectSmsBeforeHours);
    setSmsDuringHours(projectSmsDuringHours);
    setSmsAfterHours(projectSmsAfterHours);
    setTags(crmProjectTags);
  }, [
    projectShortTermUsers,
    crmProjectTags,
    projectShortTermRealtorWalkIn,
    projectShortTermNoRealtorWalkIn,
    projectShortTermHasRealtorWalkIn,
    projectSales,
    projectOnline,
    projectScrum,
    projectAdmins,
    projectSmsBeforeHours,
    projectSmsDuringHours,
    projectSmsAfterHours,
  ]);

  useQuery(GETUSERS, {
    variables: { filter: { OR: [{ type: "Sales" }, { type: "Manager" }, { type: "Admin" }], locked: false } },
    onCompleted: (data) => {
      setUsers(data.userMany);
      setSales(projectSales);
      setOnline(projectOnline);
      setScrum(projectScrum);
      setAdmins(projectAdmins);
      setShortTermUsers(projectShortTermUsers);
      setShortTermRealtorWalkIn(projectShortTermRealtorWalkIn);
      setShortTermNoRealtorWalkIn(projectShortTermNoRealtorWalkIn);
      setShortTermHasRealtorWalkIn(projectShortTermHasRealtorWalkIn);
      setSmsBeforeHours(projectSmsBeforeHours);
      setSmsDuringHours(projectSmsDuringHours);
      setSmsAfterHours(projectSmsAfterHours);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [updateProject] = useMutation(UPDATEPROJECT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar("Project Successfully Updated!"));
      storeDispatch(setProject(data.projectUpdateById.record));
      setSubmitted(false);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [updateCrmProject] = useMutation(UPDATECRMPROJECT, {
    onCompleted: (data) => {
      storeDispatch(updateShortTermUsers(data.crmProjectSettingUpdateById.record.shortTermUsers));
      storeDispatch(updateShortTermRealtorWalkIn(data.crmProjectSettingUpdateById.record.shortTermRealtorWalkIn));
      storeDispatch(updateShortTermNoRealtorWalkIn(data.crmProjectSettingUpdateById.record.shortTermNoRealtorWalkIn));
      storeDispatch(updateShortTermHasRealtorWalkIn(data.crmProjectSettingUpdateById.record.shortTermHasRealtorWalkIn));
      storeDispatch(updateSales(data.crmProjectSettingUpdateById.record.sales));
      storeDispatch(updateOnline(data.crmProjectSettingUpdateById.record.online));
      storeDispatch(updateScrum(data.crmProjectSettingUpdateById.record.scrum));
      storeDispatch(updateAdmins(data.crmProjectSettingUpdateById.record.admins));
      storeDispatch(updateSmsBeforeHours(data.crmProjectSettingUpdateById.record.smsBeforeHours));
      storeDispatch(updateSmsDuringHours(data.crmProjectSettingUpdateById.record.smsDuringHours));
      storeDispatch(updateSmsAfterHours(data.crmProjectSettingUpdateById.record.smsAfterHours));
      storeDispatch(updateTags(data.crmProjectSettingUpdateById.record.tags));
      setShortTermUsers(data.crmProjectSettingUpdateById.record.shortTermUsers);
      setShortTermRealtorWalkIn(data.crmProjectSettingUpdateById.record.shortTermRealtorWalkIn);
      setShortTermNoRealtorWalkIn(data.crmProjectSettingUpdateById.record.shortTermNoRealtorWalkIn);
      setShortTermHasRealtorWalkIn(data.crmProjectSettingUpdateById.record.shortTermHasRealtorWalkIn);
      setSales(data.crmProjectSettingUpdateById.record.sales);
      setOnline(data.crmProjectSettingUpdateById.record.online);
      setScrum(data.crmProjectSettingUpdateById.record.scrum);
      setAdmins(data.crmProjectSettingUpdateById.record.admins);
      setSmsBeforeHours(data.crmProjectSettingUpdateById.record.smsBeforeHours);
      setSmsDuringHours(data.crmProjectSettingUpdateById.record.smsDuringHours);
      setSmsAfterHours(data.crmProjectSettingUpdateById.record.smsAfterHours);
      setAdmins(data.crmProjectSettingUpdateById.record.admins);
      setTags(data.crmProjectSettingUpdateById.record.tags);
      setSubmitted(false);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleEditProject = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitted(true);

    updateProject({
      variables: {
        projectId,
        record: {
          name: projectName,
          developerName,
          addresses,
          email,
        },
      },
    });
    updateCrmProject({
      variables: {
        _id: crmProjectId,
        record: {
          shortTermUsers: shortTermUsers.map((user: IUser) => user._id),
          shortTermRealtorWalkIn: shortTermRealtorWalkIn.map((user: IUser) => user._id),
          shortTermNoRealtorWalkIn: shortTermNoRealtorWalkIn.map((user: IUser) => user._id),
          shortTermHasRealtorWalkIn: shortTermHasRealtorWalkIn.map((user: IUser) => user._id),
          sales: sales.map((user: IUser) => user._id),
          online: online.map((user: IUser) => user._id),
          scrum: scrum.map((user: IUser) => user._id),
          admins: admins.map((user: IUser) => user._id),
          smsBeforeHours: smsBeforeHours.map((user: IUser) => user._id),
          smsDuringHours: smsDuringHours.map((user: IUser) => user._id),
          smsAfterHours: smsAfterHours.map((user: IUser) => user._id),
          tags,
        },
      },
    });
  };

  const handleAddUser = (e: any, value: any, type: string) => {
    let seen = new Set();
    let hasDuplicates = value.some((user: IUser) => {
      return seen.size === seen.add(user.fullName).size;
    });

    if (type === "online") {
      if (!hasDuplicates || value.length < shortTermUsers.length) {
        setShortTermUsers(value);
      } else {
        return storeDispatch(showErrorSnackbar("User has already been added"));
      }
    } else if (type === "realtor") {
      if (!hasDuplicates || value.length < shortTermRealtorWalkIn.length) {
        setShortTermRealtorWalkIn(value);
      } else {
        return storeDispatch(showErrorSnackbar("User has already been added"));
      }
    } else if (type === "noRealtor") {
      if (!hasDuplicates || value.length < shortTermNoRealtorWalkIn.length) {
        setShortTermNoRealtorWalkIn(value);
      } else {
        return storeDispatch(showErrorSnackbar("User has already been added"));
      }
    } else if (type === "hasRealtor") {
      if (!hasDuplicates || value.length < shortTermHasRealtorWalkIn.length) {
        setShortTermHasRealtorWalkIn(value);
      } else {
        return storeDispatch(showErrorSnackbar("User has already been added"));
      }
    } else if (type === "sales") {
      if (!hasDuplicates || value.length < sales.length) {
        setSales(value);
      } else {
        return storeDispatch(showErrorSnackbar("User has already been added"));
      }
    } else if (type === "onlineRep") {
      if (!hasDuplicates || value.length < online.length) {
        setOnline(value);
      } else {
        return storeDispatch(showErrorSnackbar("User has already been added"));
      }
    } else if (type === "scrum") {
      if (!hasDuplicates || value.length < scrum.length) {
        setScrum(value);
      } else {
        return storeDispatch(showErrorSnackbar("User has already been added"));
      }
    } else if (type === "admins") {
      if (!hasDuplicates || value.length < admins.length) {
        setAdmins(value);
      } else {
        return storeDispatch(showErrorSnackbar("User has already been added"));
      }
    } else if (type === "smsBeforeHours") {
      if (!hasDuplicates || value.length < smsBeforeHours.length) {
        setSmsBeforeHours(value);
      } else {
        return storeDispatch(showErrorSnackbar("User has already been added"));
      }
    } else if (type === "smsAfterHours") {
      if (!hasDuplicates || value.length < smsAfterHours.length) {
        setSmsAfterHours(value);
      } else {
        return storeDispatch(showErrorSnackbar("User has already been added"));
      }
    } else if (type === "smsDuringHours") {
      if (!hasDuplicates || value.length < smsDuringHours.length) {
        setSmsDuringHours(value);
      } else {
        return storeDispatch(showErrorSnackbar("User has already been added"));
      }
    }
  };

  const addTag = () => {
    if (tagValue) {
      setTags([...tags, tagValue]);
    } else return storeDispatch(showErrorSnackbar("No Tag Value Found"));
  };

  const deleteTag = (numIndex: number) => {
    let filteredTags = tags.filter((tag: string, index: number) => numIndex !== index);
    setTags(filteredTags);
  };

  const addAddress = () => {
    setAddresses([
      ...addresses,
      {
        streetAddress: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
      },
    ]);
  };

  const handleAddress = (e: any, numIndex: number) => {
    let address = addresses.map((address: IAddress, index: number) => {
      if (index === numIndex) {
        return {
          ...address,
          [e.target.name]: e.target.value,
        };
      } else return address;
    });
    setAddresses(address);
  };

  return (
    <SettingContainer>
      <Typography sx={{ mb: 3 }} variant="h2" gutterBottom>
        <strong>Edit Project Details</strong>
      </Typography>
      <form onSubmit={handleEditProject}>
        <ProjectInfo
          projectName={projectName}
          setProjectName={setProjectName}
          developerName={developerName}
          setDeveloperName={setDeveloperName}
          addresses={addresses}
          addAddress={addAddress}
          handleAddress={handleAddress}
          email={email}
          setEmail={setEmail}
          submitted={submitted}
        />
        <Box sx={{ my: 2 }}>
          <Typography sx={{ mb: 2 }} variant="h2" gutterBottom>
            <strong>Scrum Lord</strong>
          </Typography>
          <Autocomplete
            id="project-sales"
            multiple
            options={users}
            freeSolo={false}
            value={scrum}
            getOptionLabel={(option: any) => option.fullName}
            onChange={(e, value) => handleAddUser(e, value, "scrum")}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Scrum Lord" />}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography sx={{ mb: 2 }} variant="h2" gutterBottom>
            <strong>Onsite Reps</strong>
          </Typography>
          <Autocomplete
            id="project-sales"
            multiple
            options={users}
            freeSolo={false}
            value={sales}
            getOptionLabel={(option: any) => option.fullName}
            onChange={(e, value) => handleAddUser(e, value, "sales")}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Onsite Reps" />}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography sx={{ mb: 2 }} variant="h2" gutterBottom>
            <strong>Online Reps</strong>
          </Typography>
          <Autocomplete
            id="project-sales"
            multiple
            options={users}
            freeSolo={false}
            value={online}
            getOptionLabel={(option: any) => option.fullName}
            onChange={(e, value) => handleAddUser(e, value, "onlineRep")}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Online Reps" />}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography sx={{ mb: 2 }} variant="h2" gutterBottom>
            <strong>Admins</strong>
          </Typography>
          <Autocomplete
            id="project-sales"
            multiple
            options={users}
            freeSolo={false}
            value={admins}
            getOptionLabel={(option: any) => option.fullName}
            onChange={(e, value) => handleAddUser(e, value, "admins")}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Admins" />}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography sx={{ mb: 2 }} variant="h2" gutterBottom>
            <strong>Default Short Term Online Processes</strong>
          </Typography>
          <Autocomplete
            id="process-steps-online"
            multiple
            options={users}
            freeSolo={false}
            value={shortTermUsers}
            getOptionLabel={(option: any) => option.fullName}
            onChange={(e, value) => handleAddUser(e, value, "online")}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Users Selected for Short Term Default Online Process" />
            )}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography sx={{ mb: 2 }} variant="h2" gutterBottom>
            <strong>Default Short Term Realtor Walk In Process</strong>
          </Typography>
          <Autocomplete
            id="process-steps-walkin"
            multiple
            options={users}
            freeSolo={false}
            value={shortTermRealtorWalkIn}
            getOptionLabel={(option: any) => option.fullName}
            onChange={(e, value) => handleAddUser(e, value, "realtor")}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Users Selected for Short Term Default Realtor Walk in Process" />
            )}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography sx={{ mb: 2 }} variant="h2" gutterBottom>
            <strong>Default Short Term No Realtor Walk In Process</strong>
          </Typography>
          <Autocomplete
            id="process-steps-no-realtor-walkin"
            multiple
            options={users}
            freeSolo={false}
            value={shortTermNoRealtorWalkIn}
            getOptionLabel={(option: any) => option.fullName}
            onChange={(e, value) => handleAddUser(e, value, "noRealtor")}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Users Selected for Short Term Default No Realtor Walk in Process" />
            )}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography sx={{ mb: 2 }} variant="h2" gutterBottom>
            <strong>Default Short Term Has Realtor Walk In Process</strong>
          </Typography>
          <Autocomplete
            id="process-steps-has-realtor-walkin"
            multiple
            options={users}
            freeSolo={false}
            value={shortTermHasRealtorWalkIn}
            getOptionLabel={(option: any) => option.fullName}
            onChange={(e, value) => handleAddUser(e, value, "hasRealtor")}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Users Selected for Short Term Default Has Realtor Walk in Process" />
            )}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography sx={{ mb: 2 }} variant="h2" gutterBottom>
            <strong>SMS Before Hours (9am - 11am)</strong>
          </Typography>
          <Autocomplete
            id="sms-before-hours"
            multiple
            options={users}
            freeSolo={false}
            value={smsBeforeHours}
            getOptionLabel={(option: any) => option.fullName}
            onChange={(e, value) => handleAddUser(e, value, "smsBeforeHours")}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Receive SMS Before Hours (9am - 11am)" />}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography sx={{ mb: 2 }} variant="h2" gutterBottom>
            <strong>SMS During Hours (11am - 6pm)</strong>
          </Typography>
          <Autocomplete
            id="sms-during-hours"
            multiple
            options={users}
            freeSolo={false}
            value={smsDuringHours}
            getOptionLabel={(option: any) => option.fullName}
            onChange={(e, value) => handleAddUser(e, value, "smsDuringHours")}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Receive SMS During Hours (11am - 6pm)" />}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography sx={{ mb: 2 }} variant="h2" gutterBottom>
            <strong>SMS After Hours (6pm - 9pm)</strong>
          </Typography>
          <Autocomplete
            id="sms-after-hours"
            multiple
            options={users}
            freeSolo={false}
            value={smsAfterHours}
            getOptionLabel={(option: any) => option.fullName}
            onChange={(e, value) => handleAddUser(e, value, "smsAfterHours")}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Receive SMS After Hours (6pm - 9pm)" />}
          />
        </Box>
        <Box>
          <Typography sx={{ mb: 2 }} variant="h2" gutterBottom>
            <strong>Tags</strong>
          </Typography>
          <Box sx={{ display: "flex" }}>
            <TextField
              sx={{ width: "50%" }}
              fullWidth
              label="Enter a Tag"
              variant="outlined"
              value={tagValue}
              onChange={(e) => setTagValue(e.target.value)}
              size="small"
            />
            <Button sx={{ ml: 1 }} variant="contained" color="primary" onClick={() => addTag()}>
              Add Tag
            </Button>
          </Box>
          {tags.length ? (
            <Grid container spacing={2}>
              {tags.map((tag: string, index: number) => {
                return (
                  <Grid key={index} item sx={{ mt: 2 }}>
                    <Box sx={{ display: "flex", p: 1, border: "1px solid #000", borderRadius: "8px" }}>
                      {tag}
                      <CloseIcon sx={{ ml: 1, cursor: "pointer" }} onClick={() => deleteTag(index)} />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          ) : null}
        </Box>
        <Button sx={{ mt: 2 }} variant="contained" type="submit" color="success">
          Save
        </Button>
      </form>
    </SettingContainer>
  );
};

interface ChildProps {
  project: IProject | null;
}

const GETUSERS = gql`
  query userMany($filter: FilterFindManyUserInput!) {
    userMany(filter: $filter) {
      _id
      fullName
    }
  }
`;

const UPDATEPROJECT = gql`
  mutation updateProject($projectId: MongoID!, $record: UpdateByIdProjectInput!) {
    projectUpdateById(_id: $projectId, record: $record) {
      record {
        _id
        name
        developerName
        email
        addresses {
          streetAddress
          city
          province
          country
          postalCode
        }
      }
    }
  }
`;

const UPDATECRMPROJECT = gql`
  mutation crmProjectSettingUpdateById($_id: MongoID!, $record: UpdateByIdCrmProjectSettingInput!) {
    crmProjectSettingUpdateById(_id: $_id, record: $record) {
      record {
        _id
        shortTermUsers {
          _id
          fullName
        }
        shortTermRealtorWalkIn {
          _id
          fullName
        }
        shortTermNoRealtorWalkIn {
          _id
          fullName
        }
        shortTermHasRealtorWalkIn {
          _id
          fullName
        }
        sales {
          _id
          firstName
          lastName
          fullName
        }
        online {
          _id
          firstName
          lastName
          fullName
        }
        scrum {
          _id
          firstName
          lastName
          fullName
        }
        smsDuringHours {
          _id
          firstName
          lastName
          fullName
        }
        smsBeforeHours {
          _id
          firstName
          lastName
          fullName
        }
        smsAfterHours {
          _id
          firstName
          lastName
          fullName
        }
        admins {
          _id
          firstName
          lastName
          fullName
        }
        tags
      }
    }
  }
`;

export default General;
