import { SetStateAction, Dispatch, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Grid } from "@mui/material";
import { useSelector } from "react-redux";

import { IUser } from "../../types/user";
import { selectUser } from "../../features/auth/authSlice";

const CallFilter = (props: ChildProps) => {
  const user = useSelector(selectUser);
  const {
    dateStart,
    setDateStart,
    dateEnd,
    setDateEnd,
    projects,
    setProjects,
    answerStatus,
    setAnswerStatus,
    direction,
    setDirection,
    setPageNumber,
    selectedUser,
    setSelectedUser,
  } = props;
  const [users, setUsers] = useState<IUser[]>([]);

  useQuery(GETUSERS, {
    skip: user?.type !== "Manager",
    variables: { filter: { OR: [{ type: "Sales" }, { type: "Manager" }, { type: "Admin" }], locked: false } },
    onCompleted: (data) => {
      setUsers(data.userMany);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date Start"
            value={dateStart ? new Date(dateStart!) : null}
            onChange={(newValue) => {
              setDateStart(newValue);
            }}
            maxDate={dateEnd ? new Date(dateEnd!) : null}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date End"
            value={dateEnd ? new Date(dateEnd!) : null}
            onChange={(newValue) => {
              setDateEnd(newValue);
            }}
            minDate={dateStart ? new Date(dateStart!) : null}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Autocomplete
          multiple
          options={[user?.projectAccess!.map((projectAccess: any) => projectAccess?.project.name!)].flat()}
          freeSolo={false}
          value={projects}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option: any) => option}
          onChange={(e, value) => {
            setPageNumber(0);
            setProjects(value);
          }}
          renderInput={(params) => <TextField {...params} label="Projects" />}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Answer Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name={"answerType"}
            label={"Answer Type"}
            value={answerStatus}
            onChange={(e) => setAnswerStatus(e.target.value)}
          >
            <MenuItem value={"answered"}>Answered</MenuItem>
            <MenuItem value={"missed"}>Missed</MenuItem>
            <MenuItem value={"voicemail"}>Voicemail</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Direction</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name={"direction"}
            label={"Direction"}
            value={direction}
            onChange={(e) => setDirection(e.target.value)}
          >
            <MenuItem value={"inbound"}>Inbound</MenuItem>
            <MenuItem value={"outbound"}>Outbound</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Autocomplete
          id="users"
          options={users}
          freeSolo={false}
          getOptionLabel={(option: any) => option.fullName}
          isOptionEqualToValue={(option, value) => option === value}
          value={selectedUser}
          onChange={(e, value) => setSelectedUser(value!)}
          renderInput={(params) => <TextField {...params} variant="outlined" label="User" />}
        />
      </Grid>
    </Grid>
  );
};

interface ChildProps {
  dateStart: Date | null;
  setDateStart: Dispatch<SetStateAction<Date | null>>;
  dateEnd: Date | null;
  setDateEnd: Dispatch<SetStateAction<Date | null>>;
  answerStatus: string;
  setAnswerStatus: Dispatch<SetStateAction<string>>;
  direction: string;
  setDirection: Dispatch<SetStateAction<string>>;
  projects: any[];
  setProjects: any;
  setPageNumber: Dispatch<SetStateAction<number>>;
  selectedUser: IUser | undefined;
  setSelectedUser: Dispatch<SetStateAction<IUser | undefined>>;
}

const GETUSERS = gql`
  query userMany($filter: FilterFindManyUserInput!) {
    userMany(filter: $filter) {
      _id
      email
      fullName
    }
  }
`;

export default CallFilter;
