import { useState, useMemo, useEffect } from "react";
import { Checkbox, Typography, Box, Button, Divider } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useMutation, useLazyQuery, gql, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

import { IRegistrant } from "../../types/registrant";
import { FlexBetween, Container } from "../../commonStyles";
import StandardTable from "../tables/StandardTable";
import { IUser } from "../../types/user";
import { IEmailTemplate, IProcess, IStep, IStepProcess } from "../../types/process";
import { IStepSchedule } from "../../types/stepSchedule";
import {
  addtoRegistrantProcess,
  selectProcess,
  setProcess,
  deleteStep,
  updateStep,
  terminateRegistrantProcess,
  selectActiveProcesses,
  setActiveProcesses,
} from "../../features/registrants/registrantsSlice";
import { camelToNormal, convertAllDates } from "../../utils/function";
import { selectTemplates } from "../../features/emailTemplate/emailTemplateSlice";
import { GlobalModal } from "../../features/modal/Modal";
import { useAppDispatch } from "../../app/hooks";
import { handleModal } from "../../features/modal/modalSlice";
import { showSuccessSnackbar, showErrorSnackbar } from "../../features/snackbar/snackbarSlice";
import { selectProjectProcesses } from "../../features/projectSetting/projectSettingSlice";
import { selectUser } from "../../features/auth/authSlice";
import { selectUsers, setUsers } from "../../features/user/userSlice";

import DraggableList from "../common/DraggableList";
import CreateProcessSteps from "../settings/process/CreateProcessSteps";
import HistoryProcessView from "./process/HistoryProcessView";
import EditStepView from "./process/EditStepView";
import ProcessView from "./process/ProcessView";

const Processes = (props: ChildProps) => {
  const { registrant } = props;
  const process = useSelector(selectProcess);
  const activeProcesses = useSelector(selectActiveProcesses);
  const processes = useSelector(selectProjectProcesses);
  const user = useSelector(selectUser);
  const users = useSelector(selectUsers);
  const templates = useSelector(selectTemplates);
  const storeDispatch = useAppDispatch();

  const [selectedProcess, setSelectedProcess] = useState<string>("");
  const [selectedStep, setSelectedStep] = useState<string | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>(process?.users ? process?.users : []);
  const [modalView, setModalView] = useState<string>("");
  // Step States
  const [emailTemplate, setEmailTemplate] = useState<IEmailTemplate | null>(null);
  const [name, setName] = useState<string>("");
  const [defaultStep, setDefaultStep] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [time, setTime] = useState<string>("24");
  // StepSchedule
  const [stepSchedules, setStepSchedules] = useState<IStepSchedule[]>([]);
  // Selected Step State
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [dateCompleted, setDateCompleted] = useState<Date | null>(null);
  const [userCompleted, setUserCompleted] = useState<IUser | null>(null);
  const [stepTime, setStepTime] = useState(""); // Time Between Step for that step

  // Queries

  useEffect(() => {
    if (process) {
      setSelectedUsers(process.users);
    }
  }, [process]);

  useQuery(GETSCHEDULEDSTEPS, {
    variables: { filter: { registrant: registrant._id, project: registrant.project._id } },
    onCompleted: (data) => {
      setStepSchedules([...data.stepScheduleMany].reverse());
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [getUsers] = useLazyQuery(GETUSERS, {
    onCompleted: (data) => {
      storeDispatch(setUsers(data.userPagination));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [createProcess] = useMutation(CREATEPROCESS, {
    onCompleted: (data) => {
      storeDispatch(setProcess(data.createProcess));
      storeDispatch(setActiveProcesses([data.createProcess]));
      storeDispatch(addtoRegistrantProcess(data.createProcess));
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar("Process Created!"));
      setSelectedProcess("");
      setModalView("");
    },
  });

  const [updateProcess] = useMutation(UPDATEPROCESS, {
    onCompleted: (data) => {
      storeDispatch(setProcess(data.processUpdateById.record));
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar("Process Updated!"));
      setSelectedProcess("");
      setModalView("");
    },
  });

  // Rearranging, update multiple steps
  const [updateProcessSteps] = useMutation(UPDATESTEPS, {
    onCompleted: (data) => {
      storeDispatch(setProcess(data.updateSteps));
    },
  });

  // edit, update a single step
  const [updateSingleStep] = useMutation(UPDATESTEP, {
    onCompleted: (data) => {
      storeDispatch(updateStep(data.stepUpdateById.record));
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar("Step Updated!"));
      setSelectedStep("");
      setModalView("");
    },
  });

  const [addProcessStep] = useMutation(ADDSTEP, {
    onCompleted: (data) => {
      storeDispatch(setProcess(data.addStep));
      storeDispatch(handleModal(false));
      setEmailTemplate(null);
      setName("");
      setDefaultStep(false);
      setType("");
      storeDispatch(showSuccessSnackbar("Step Added!"));
      setSelectedProcess("");
      setModalView("");
    },
  });

  const [reorderSteps] = useMutation(REORDERSTEPS, {
    onCompleted: (data) => {
      storeDispatch(setProcess(data.reorderSteps));
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar("Steps have been reordered!"));
      setSelectedProcess("");
      setModalView("");
    },
  });

  const columns = useMemo(() => {
    return [
      {
        Header: "Completed",
        accessor: (rowData: IStepProcess, index: number) => {
          if (index === 0) {
            return <strong>{index + 1}</strong>;
          } else {
            return (
              <Box>
                <strong>{index + 1}</strong>
                <Checkbox onChange={(e) => handleCompleteStep(rowData, rowData.process, true)} checked={rowData.completed ? true : false} />
              </Box>
            );
          }
        },
      },
      {
        Header: "Name",
        accessor: (rowData: IStepProcess, index: number) => {
          let stepSchedule: any = stepSchedules.find(
            (stepSchedule: IStepSchedule) => stepSchedule.step && stepSchedule.step._id === rowData._id
          );
          return (
            <Box sx={{ cursor: "pointer" }} onClick={() => handleEditStep(rowData, index)}>
              <Box>
                <strong>{rowData.name}</strong>
              </Box>
              {stepSchedule ? (
                <Box sx={{ fontSize: "11px" }}>
                  <span>Scheduled Email: {convertAllDates(stepSchedule.mailDate, "PPpp")}</span>
                </Box>
              ) : null}
            </Box>
          );
        },
      },
      {
        Header: "Automated",
        accessor: (rowData: IStepProcess, index: number) =>
          rowData.type === "automated" ? <CheckIcon sx={{ color: "success.main" }} /> : <CloseIcon sx={{ color: "error.main" }} />,
      },
      {
        Header: "Date Due",
        accessor: (rowData: IStepProcess) => {
          let color = "#000";
          if (
            rowData.dueDate &&
            new Date().setHours(23, 59, 59, 59).valueOf() > new Date(rowData.dueDate).valueOf() &&
            !rowData.completed
          ) {
            color = "error.main";
          }
          if (rowData.dueDate) {
            return (
              <Box sx={{ color: color }}>
                <strong>{convertAllDates(rowData.dueDate, "PP")}</strong>
              </Box>
            );
          } else return "-";
        },
      },
      {
        Header: "Email Template",
        accessor: (rowData: IStepProcess) => {
          if (rowData.emailTemplate) {
            return rowData.emailTemplate.name;
          } else return "-";
        },
      },
      {
        Header: "Completed Date",
        accessor: (rowData: IStepProcess) => {
          let color = "#000";
          if (
            rowData.dueDate &&
            new Date().setHours(23, 59, 59, 59).valueOf() > new Date(rowData.dueDate).valueOf() &&
            !rowData.completed
          ) {
            color = "error.main";
          }
          if (rowData.completed) {
            return <Box sx={{ color: "success.main" }}>{convertAllDates(rowData.completed, "PPpp")}</Box>;
          } else return <Box sx={{ color: color }}>Not Completed</Box>;
        },
      },
      {
        Header: "Completed By",
        accessor: (rowData: IStepProcess) => {
          if (rowData.user) {
            return rowData.user.fullName;
          } else return "-";
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrant, process, stepSchedules]);

  // Functions

  const handleTime = (e: any) => {
    if (e.target.name === "hours") {
      setStepTime(e.target.value);
    } else if (e.target.name === "days") {
      setStepTime(e.target.value ? (e.target.value * 24).toString() : "");
    }
  };

  const handleUser = (fullName: string, type: string) => {
    let user = users.find((user: IUser) => fullName === user.fullName);
    if (user) {
      if (type === "edit") {
        setUserCompleted(user);
      } else {
        setSelectedUsers([...selectedUsers, user]);
      }
    } else return storeDispatch(showErrorSnackbar("No User Found"));
  };

  const handleCompleteStep = async (data: IStepProcess, process: IProcess | null = null, checkbox: boolean = false) => {
    if (data.completed && user?._id !== data.user._id) {
      if (user?.type !== "Manager") return storeDispatch(showErrorSnackbar("Cannot Uncheck."));
    }

    if (process) {
      let nextStepIndex: number = (await process.steps.findIndex((step: any) => step._id === data._id)) + 1;
      let previousStepIndex: number = (await process.steps.findIndex((step: any) => step._id === data._id)) - 1;

      if (process.steps[nextStepIndex] && process.steps[nextStepIndex].completed)
        return storeDispatch(showErrorSnackbar("Please uncheck the next step first"));

      if (process.steps[previousStepIndex] && !process.steps[previousStepIndex].completed)
        return storeDispatch(showErrorSnackbar("Previous Step is incomplete"));

      updateProcessSteps({
        variables: {
          registrantId: registrant._id,
          processId: process ? process._id : data.process,
          stepId: data._id,
          userId: user?._id,
          checkbox,
        },
      });
    }
  };

  const handleEditStep = (data: IStepProcess, index: number) => {
    if (data.completed && data.user) {
      getUsers({
        variables: {
          page: 1,
          perPage: 25,
          sort: "_ID_DESC",
          filter: { OR: [{ type: "Sales" }, { type: "Manager" }, { type: "Admin" }], locked: false },
        },
      });
    }
    storeDispatch(setProcess(data.process));
    setSelectedStep(data._id);
    setName(data.name);
    setEmailTemplate(data.emailTemplate!);
    setDueDate(data.dueDate);
    setUserCompleted(data.user);
    setDateCompleted(data.completed);
    setStepTime(process ? process?.timeBetweenSteps[index] : "");
    handleProcess("editStep", null, true);
  };

  const handleProcess = async (view: string, index: number | null, modalOpen: boolean, historyProcess = null) => {
    if (index !== null) {
      storeDispatch(setProcess(activeProcesses[index]));
    }
    if (view === "newProcess" || view === "editProcess") {
      if (!historyProcess) {
        setSelectedProcess(activeProcesses.length ? activeProcesses[index!]._id : process?._id!);
      } else {
        setSelectedProcess(historyProcess);
      }
      await getUsers({
        variables: {
          page: 1,
          perPage: 100,
          sort: "_ID_DESC",
          filter: { OR: [{ type: "Sales" }, { type: "Manager" }, { type: "Admin" }], locked: false },
        },
      });
    }
    setModalView(view);
    if (view !== "editStep") {
      storeDispatch(handleModal(modalOpen));
    }
  };

  const handleChangeProcess = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (selectedProcess) {
      // Turn current process to false and add longterm process
      let processUsers = selectedUsers.map((user: IUser) => user._id);
      if (modalView === "editProcess") {
        updateProcess({ variables: { _id: process?._id, record: { users: processUsers } } });
      } else {
        createProcess({
          variables: { registrantId: registrant._id, processTemplateId: selectedProcess, terminate: process?._id, userIds: processUsers },
        });
        if (process) {
          storeDispatch(terminateRegistrantProcess());
        }
      }
    } else {
      updateProcess({ variables: { _id: process?._id, record: { currentProcess: false } } });
      setProcess(null);
      // Turn current process to false without adding longterm process
    }
  };

  const handleAddStepSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    addProcessStep({
      variables: {
        record: {
          emailTemplate: emailTemplate ? emailTemplate._id : null,
          name,
          default: defaultStep,
          type,
          project: registrant.project._id,
          registrant: registrant._id,
        },
        processId: process?._id,
        time,
      },
    });
    storeDispatch(handleModal(false));
  };

  const handleSingleStep = () => {
    updateSingleStep({
      variables: {
        _id: selectedStep,
        record: {
          name,
          completed: dateCompleted,
          emailTemplate: emailTemplate ? emailTemplate._id : null,
          dueDate: dueDate,
          user: userCompleted?._id,
        },
      },
    });
  };

  const handleEmailTemplate = (emailTemplate: string) => {
    let selectedEmailTemplate = templates.find((template: IEmailTemplate) => emailTemplate === template.name);

    if (selectedEmailTemplate) {
      setEmailTemplate(selectedEmailTemplate);
    }
  };

  const handleView = () => {
    switch (modalView) {
      case "deleteProcess":
        return (
          <Box>
            <Typography variant="h2">
              <strong>Delete Process?</strong>
            </Typography>
            <p>Would you like to delete this process?</p>
            <FlexBetween>
              <Button
                color="info"
                variant="contained"
                onClick={() => {
                  setModalView("");
                  storeDispatch(handleModal(false));
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  updateProcess({ variables: { _id: process?._id, record: { currentProcess: false } } });
                  setProcess(null);
                }}
                color="error"
                variant="contained"
              >
                Delete
              </Button>
            </FlexBetween>
          </Box>
        );
      case "historyProcess":
        return <HistoryProcessView registrant={registrant} historyColumns={columns.slice(1)} handleProcess={handleProcess} />;
      case "newProcess":
      case "editProcess":
        return (
          <form onSubmit={handleChangeProcess}>
            <ProcessView
              view={modalView}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              processes={processes}
              selectedProcess={selectedProcess}
              setSelectedProcess={setSelectedProcess}
              user={user}
              userCompleted={userCompleted}
              handleUser={handleUser}
              users={users}
              handleProcess={handleProcess}
            />
          </form>
        );
      case "addStep":
        return (
          <div>
            <p>
              <strong>
                This step will be added to the last step in the process with a due date of 2 days after the previous completed step.
              </strong>
            </p>
            <CreateProcessSteps
              stepId={""}
              handleStepSubmit={handleAddStepSubmit}
              name={name}
              setName={setName}
              emailTemplate={emailTemplate}
              setEmailTemplate={handleEmailTemplate}
              type={type}
              setType={setType}
              defaultStep={defaultStep}
              setDefaultStep={setDefaultStep}
              time={time}
              setTime={setTime}
              create={false}
            />
          </div>
        );
      case "reorderStep":
        if (process) {
          const deleteProcessStep = (step: IStep, index: number) => {
            if (step.completed) return storeDispatch(showErrorSnackbar("Cannot Delete Completed Step!"));
            storeDispatch(deleteStep({ id: step._id, index: index }));
          };

          let editableSteps = process.steps.map((step: IStep, index: number) => {
            return (
              <FlexBetween key={index}>
                <div>
                  {step.name} - {step.dueDate ? convertAllDates(step.dueDate, "PP") : "No Due Date"} -{" "}
                  {step.completed ? convertAllDates(step.completed, "PP") : "Not Completed"}
                </div>
                <div>
                  <DeleteForeverIcon onClick={() => deleteProcessStep(step, index)} sx={{ color: "error.main" }} />
                </div>
              </FlexBetween>
            );
          });

          const handleSave = (order: React.MutableRefObject<number[]>) => {
            let newTime: string[] = [];
            let newOrder = order.current.map((order: number, index: number) => {
              newTime.push(process.timeBetweenSteps[order]);
              return process.steps[order]._id;
            });

            let validateOrder = newOrder.some((id: string, index: number) => {
              let step = process.steps.find((step: IStep) => id === step._id);
              let nextStep = process.steps.find((step: IStep) => newOrder[index + 1] === step._id);

              if (step && !step?.completed && nextStep && nextStep?.completed) {
                return storeDispatch(showErrorSnackbar("Cannot Set Completed after Non Completed Step"));
              } else return false;
            });

            if (validateOrder) return;

            reorderSteps({
              variables: {
                stepIds: newOrder,
                timeBetweenSteps: newTime,
                processId: process?._id,
              },
            });
          };

          return (
            <Box>
              <Typography variant="h2" component="div" gutterBottom>
                <strong>Steps</strong>
              </Typography>
              <p>Drag the steps below to reorder. Please note that rearranging steps will reset the due dates of the step.</p>
              <DraggableList
                items={editableSteps}
                itemProps={process.steps}
                handleSave={handleSave}
                close={() => handleProcess("", null, false)}
              />
            </Box>
          );
        } else return;
      default:
        return;
    }
  };

  return (
    <Container>
      {modalView ? <GlobalModal onClose={() => setModalView("")}>{handleView()}</GlobalModal> : null}
      {activeProcesses.length && activeProcesses.some((process: IProcess) => process.currentProcess) && modalView !== "editStep" ? (
        activeProcesses
          .filter(
            (process: IProcess) =>
              process.currentProcess &&
              ((user?.type !== "Manager" && process.viewType === user?.type) || process.viewType === "All" || user?.type === "Manager")
          )
          .map((process: IProcess, index: number) => {
            return (
              <Box sx={{ mt: index > 0 ? 2 : 0 }} key={index}>
                {index > 0 ? <Divider sx={{ mb: 2, borderBottomWidth: 5 }} /> : null}
                <FlexBetween sx={{ mb: 1 }}>
                  <Typography variant="h2" component="div" gutterBottom>
                    <strong>
                      {camelToNormal(process?.processType!)} - <em>{process?.name}</em>
                    </strong>
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", md: "row" } }}>
                    <Button color="primary" variant="contained" onClick={() => handleProcess("editProcess", index, true)}>
                      Edit Users in Process
                    </Button>
                  </Box>
                </FlexBetween>
                <div>
                  <StandardTable
                    data={process?.steps!.map((step: any) => {
                      return { ...step, process: process };
                    })}
                    columns={columns}
                  />
                  <FlexBetween sx={{ mt: 2, flexWrap: "wrap" }}>
                    <Box sx={{ mt: 1, display: "flex", flexDirection: { xs: "column", md: "row" } }}>
                      <Button
                        onClick={() => handleProcess("addStep", index, true)}
                        sx={{ mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 }, width: { xs: "100%", md: "max-content" } }}
                        color="info"
                        variant="contained"
                      >
                        Add Step
                      </Button>
                      <Button
                        onClick={() => handleProcess("reorderStep", index, true)}
                        sx={{ mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 }, width: { xs: "100%", md: "max-content" } }}
                        color="info"
                        variant="contained"
                        disabled={process && process.timeBetweenSteps.some((stepTime: any) => isNaN(stepTime))}
                      >
                        Reorder Step
                      </Button>
                      <Button
                        onClick={() => handleProcess("deleteProcess", index, true)}
                        sx={{ mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 }, width: { xs: "100%", md: "max-content" } }}
                        color="error"
                        variant="contained"
                      >
                        Delete Process
                      </Button>
                      {registrant.process.length > 1 ? (
                        <Button
                          onClick={() => handleProcess("historyProcess", index, true)}
                          sx={{ mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 }, width: { xs: "100%", md: "max-content" } }}
                          color="secondary"
                          variant="contained"
                        >
                          History ({registrant.process.length})
                        </Button>
                      ) : null}
                    </Box>
                    <Button
                      sx={{ mt: 1, height: "max-content" }}
                      onClick={() => handleProcess("newProcess", index, true)}
                      color="success"
                      variant="contained"
                    >
                      Assign New Process
                    </Button>
                  </FlexBetween>
                </div>
              </Box>
            );
          })
      ) : modalView === "editStep" ? (
        <Box>
          <EditStepView
            name={name}
            setName={setName}
            emailTemplate={emailTemplate}
            setEmailTemplate={handleEmailTemplate}
            dateCompleted={dateCompleted}
            setDateCompleted={setDateCompleted}
            dueDate={dueDate}
            setDueDate={setDueDate}
            stepTime={stepTime}
            handleTime={handleTime}
            user={user}
            userCompleted={userCompleted}
            handleUser={handleUser}
            users={users}
            handleSingleStep={handleSingleStep}
            handleCompleteStep={handleCompleteStep}
            handleProcess={handleProcess}
            stepSchedules={stepSchedules}
            setStepSchedules={setStepSchedules}
            registrant={registrant}
            step={selectedStep!}
          />
        </Box>
      ) : (
        <Box>
          There are currently no active processes. Please click{" "}
          <span onClick={() => handleProcess("newProcess", null, true)} style={{ cursor: "pointer" }}>
            <strong>here</strong>
          </span>{" "}
          to add one
          {registrant.process.length ? (
            <Box sx={{ mt: 2 }}>
              <Button onClick={() => handleProcess("historyProcess", null, true)} color="secondary" variant="contained">
                History ({registrant.process.length})
              </Button>
            </Box>
          ) : null}
        </Box>
      )}
    </Container>
  );
};

interface ChildProps {
  registrant: IRegistrant;
}

const CREATEPROCESS = gql`
  mutation createProcess($registrantId: MongoID!, $processTemplateId: MongoID!, $terminate: MongoID, $userIds: [MongoID!]) {
    createProcess(registrantId: $registrantId, processTemplateId: $processTemplateId, terminate: $terminate, userIds: $userIds) {
      _id
      currentProcess
      default
      steps {
        _id
        completed
        default
        dueDate
        type
        emailTemplate {
          name
          _id
        }
        user {
          _id
          fullName
        }
        name
      }
      timeBetweenSteps
      processType
      name
      registrant {
        _id
      }
    }
  }
`;

const UPDATEPROCESS = gql`
  mutation processUpdateById($_id: MongoID!, $record: UpdateByIdProcessInput!) {
    processUpdateById(_id: $_id, record: $record) {
      record {
        _id
        currentProcess
        default
        users {
          _id
          fullName
        }
        steps {
          _id
          completed
          default
          dueDate
          type
          emailTemplate {
            name
            _id
          }
          user {
            _id
            fullName
          }
          name
        }
        timeBetweenSteps
        processType
        viewType
        name
        registrant {
          _id
        }
      }
    }
  }
`;

const UPDATESTEP = gql`
  mutation stepUpdateById($_id: MongoID!, $record: UpdateByIdStepInput!) {
    stepUpdateById(_id: $_id, record: $record) {
      record {
        _id
        completed
        default
        dueDate
        type
        emailTemplate {
          name
          _id
        }
        user {
          _id
          fullName
        }
        name
        registrant {
          _id
        }
      }
    }
  }
`;

const UPDATESTEPS = gql`
  mutation updateSteps($registrantId: MongoID!, $processId: MongoID!, $stepId: MongoID!, $userId: MongoID!, $checkbox: Boolean!) {
    updateSteps(registrantId: $registrantId, processId: $processId, stepId: $stepId, userId: $userId, checkbox: $checkbox) {
      _id
      currentProcess
      viewType
      default
      steps {
        _id
        completed
        default
        dueDate
        type
        emailTemplate {
          name
          _id
        }
        user {
          _id
          fullName
        }
        name
      }
      timeBetweenSteps
      processType
      name
      registrant {
        _id
      }
    }
  }
`;

const ADDSTEP = gql`
  mutation addStep($record: CreateOneStepInput!, $processId: MongoID!, $time: String!) {
    addStep(record: $record, processId: $processId, time: $time) {
      _id
      currentProcess
      viewType
      default
      steps {
        _id
        completed
        default
        dueDate
        type
        emailTemplate {
          name
          _id
        }
        user {
          _id
          fullName
        }
        name
      }
      timeBetweenSteps
      processType
      name
      registrant {
        _id
      }
    }
  }
`;

const REORDERSTEPS = gql`
  mutation reordersteps($stepIds: [MongoID!], $timeBetweenSteps: [String!], $processId: MongoID!) {
    reorderSteps(stepIds: $stepIds, timeBetweenSteps: $timeBetweenSteps, processId: $processId) {
      _id
      currentProcess
      viewType
      default
      steps {
        _id
        completed
        default
        dueDate
        type
        emailTemplate {
          name
          _id
        }
        user {
          _id
          fullName
        }
        name
      }
      timeBetweenSteps
      processType
      name
      registrant {
        _id
      }
    }
  }
`;

const GETUSERS = gql`
  query userPagination($filter: FilterFindManyUserInput, $page: Int!, $perPage: Int!, $sort: SortFindManyUserInput) {
    userPagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        firstName
        lastName
        fullName
      }
      count
    }
  }
`;

const GETSCHEDULEDSTEPS = gql`
  query stepScheduleMany($filter: FilterFindManyStepScheduleInput!) {
    stepScheduleMany(filter: $filter, limit: 10000) {
      _id
      mailDate
      step {
        _id
      }
    }
  }
`;

export default Processes;
