import { useState } from "react";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { Typography, Button, Box, Grid, Skeleton } from "@mui/material";

import AutoSmsTemplate from "./AutoSmsTemplate";
import { IProject } from "../../../types/project";
import { FlexBetween, FlexEnd, SettingContainer } from "../../../commonStyles";
import { useAppDispatch } from "../../../app/hooks";
import { showErrorSnackbar, showSuccessSnackbar } from "../../../features/snackbar/snackbarSlice";
import { GlobalModal } from "../../../features/modal/Modal";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import { handleModal } from "../../../features/modal/modalSlice";
import {
  selectSmsFirstMessage,
  selectSmsSecondMessage,
  selectSmsThirdMessage,
  selectSmsUnsubscribe,
  setSmsUnsubscribe,
  setSmsFirstMessage,
  setSmsSecondMessage,
  setSmsThirdMessage,
} from "../../../features/projectSetting/projectSettingSlice";

const Sms = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const { project } = props;
  const unsubscribe = useSelector(selectSmsUnsubscribe);
  const firstMessage = useSelector(selectSmsFirstMessage);
  const secondMessage = useSelector(selectSmsSecondMessage);
  const thirdMessage = useSelector(selectSmsThirdMessage);

  const [updateSmsTemplates] = useMutation(UPDATE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar("Auto SMS Templates Updated"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [sendTest] = useLazyQuery(SENDTEST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(data.sendTestTextMessage));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const handleTestSms = (text: string) => {
    sendTest({ variables: { project: project?._id, textMessage: text } });
  };

  const handleSave = () => {
    updateSmsTemplates({
      variables: {
        filter: {
          project: project?._id,
        },
        record: {
          smsUnsubscribe: unsubscribe,
          smsFirstMessage: firstMessage,
          smsSecondMessage: secondMessage,
          smsThirdMessage: thirdMessage,
        },
      },
    });
  };

  return (
    <SettingContainer>
      <GlobalModal>
        <Box>
          <Typography variant="h2">Save Auto Sms Templates</Typography>
          <p>Are you sure you would like to save these changes? Registrants will be receiving text messages when these are saved.</p>
        </Box>
        <FlexBetween>
          <Button variant="contained" color="info" onClick={() => storeDispatch(handleModal(false))}>
            Cancel
          </Button>
          <Button variant="contained" color="success" onClick={() => handleSave()}>
            Save Changes
          </Button>
        </FlexBetween>
      </GlobalModal>
      <AutoSmsTemplate
        title="Auto Unsubscribe Message"
        subTitle="Registrant will receive this text message when they register."
        sms={unsubscribe}
        setSms={setSmsUnsubscribe}
        handleTestSms={handleTestSms}
      />
      <AutoSmsTemplate
        title="First Auto SMS Message"
        subTitle="Registrant will receive this text message 1 minute after they register. If it is after office hours, it will be sent at 9am the following day"
        sms={firstMessage}
        setSms={setSmsFirstMessage}
        handleTestSms={handleTestSms}
      />
      <AutoSmsTemplate
        title="Second Auto SMS Message"
        subTitle="Registrant will receive this text message 2 minute after they register. If it is after office hours, it will be sent at 9:01am the following day"
        sms={secondMessage}
        setSms={setSmsSecondMessage}
        handleTestSms={handleTestSms}
      />
      <AutoSmsTemplate
        title="Third Auto SMS Message"
        subTitle="Registrant will receive this text message 48 hours after registration. It will only send if they have not responded to the autoamted messages."
        sms={thirdMessage}
        setSms={setSmsThirdMessage}
        handleTestSms={handleTestSms}
      />
      {user?.type === "Manager" ? (
        <FlexEnd>
          <Button onClick={() => storeDispatch(handleModal(true))} variant="contained" color="success">
            Save Changes
          </Button>
        </FlexEnd>
      ) : null}
    </SettingContainer>
  );
};

interface ChildProps {
  project: IProject | null;
}

const SENDTEST = gql`
  query sendTestTextMessage($project: MongoID!, $textMessage: String!) {
    sendTestTextMessage(project: $project, textMessage: $textMessage)
  }
`;

const UPDATE = gql`
  mutation crmProjectSettingUpdateOne($record: UpdateOneCrmProjectSettingInput!, $filter: FilterUpdateOneCrmProjectSettingInput) {
    crmProjectSettingUpdateOne(record: $record, filter: $filter) {
      record {
        _id
        smsUnsubscribe
        smsFirstMessage
        smsSecondMessage
        smsThirdMessage
      }
    }
  }
`;

export default Sms;
