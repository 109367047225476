import { useState, useEffect } from "react";
import { useLazyQuery, gql } from "@apollo/client";
import { Typography, Skeleton, Grid, Box, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

import { Container } from "../../../commonStyles";
import TextStats from "./TextStats";
import { useSelector } from "react-redux";
import { selectProject } from "../../../features/project/projectSlice";

const AllTextStats = () => {
  const project = useSelector(selectProject);
  const [texts, setTexts] = useState([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const navigate = useNavigate();

  const [getMailings, { loading }] = useLazyQuery(GETTEXTCAMPAIGN, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setTexts(data.getTextStats.items);
      setCount(data.getTextStats.count);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  useEffect(() => {
    if (project) {
      getMailings({
        variables: { project: project?._id, page: pageNumber, limit: 6 },
      });
    }
  }, [getMailings, pageNumber, project]);

  let array = new Array(6).fill(0);

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <ArrowBackIcon
          onClick={() => navigate(-1)}
          sx={{ mr: 1, cursor: "pointer", color: "primary.main", alignSelf: "center" }}
          fontSize="small"
        />
        <Typography sx={{ mb: 0, alignSelf: "center" }} variant="h2" component="div" gutterBottom>
          <strong>All Mass Mails</strong>
        </Typography>
      </Box>
      {!loading ? (
        texts.length ? (
          <Box>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {texts.map((text, index: number) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                    <Container sx={{ height: "100%" }}>
                      <TextStats text={text} />
                    </Container>
                  </Grid>
                );
              })}
            </Grid>
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <Button disabled={pageNumber === 0} onClick={() => setPageNumber(pageNumber - 1)}>
                {"<"}
              </Button>
              <span>{pageNumber + 1}</span>
              <Button disabled={pageNumber === Math.floor(count / 6)} onClick={() => setPageNumber(pageNumber + 1)}>
                {">"}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box sx={{ mt: 2 }}>There are currently no mass texts sent out</Box>
        )
      ) : (
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            {array.map((number: any, index: number) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                  <Container sx={{ height: "100%" }}>
                    <Skeleton height={40} animation="wave" />
                    <Skeleton height={200} variant="circular" animation="wave" />
                  </Container>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

const GETTEXTCAMPAIGN = gql`
  query getTextStats($project: MongoID!, $page: Int!, $limit: Int!) {
    getTextStats(project: $project, page: $page, limit: $limit) {
      count
      items {
        textSchedule {
          name
          _id
        }
        campaignId
        startDate
        state
        sent
        total
        unsubscribes
      }
    }
  }
`;

export default AllTextStats;
