import { useState, useReducer, useMemo, useEffect } from "react";
import { useLazyQuery, gql, useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { Button, Box, Typography, Checkbox, FormControlLabel, Grid } from "@mui/material";

import { SettingContainer } from "../../../commonStyles";
import StandardTable from "../../tables/StandardTable";
import { showErrorSnackbar, showSuccessSnackbar } from "../../../features/snackbar/snackbarSlice";
import { useProjectsQuery } from "../../../features/project/projectHooks";
import { useAppDispatch } from "../../../app/hooks";
import { IProject } from "../../../types/project";
import { selectProjects } from "../../../features/project/projectSlice";
import { selectUsers, setUsers, selectCount, addUser, updateUser } from "../../../features/user/userSlice";
import { selectUser } from "../../../features/auth/authSlice";
import { userReducer } from "../../../features/user/userHooks";
import { IProjectAccess, IUser } from "../../../types/user";
import { convertAllDates } from "../../../utils/function";
import RealtorForm from "./RealtorForm";
import AccountForm from "./AccountForm";

const Users = () => {
  const projects = useSelector(selectProjects);
  const users = useSelector(selectUsers);
  const user = useSelector(selectUser);
  const count = useSelector(selectCount);

  const [smsUsers, setSmsUsers] = useState<any[]>([]);
  const [view, setView] = useState<string>("all");
  const [userId, setUserId] = useState<string>("");
  const [realtorId, setRealtorId] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [primaryPhone, setPrimaryPhone] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(true);
  const [locked, setLocked] = useState<boolean>(false);
  const [receiveSms, setReceiveSms] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [verified, setVerified] = useState<boolean>(false);
  const projectAccess: any[] = [];
  const [state, dispatch] = useReducer(userReducer, projectAccess);
  // Realtor State
  const [brokerage, setBrokerage] = useState<string>("");
  const [brokeragePhone, setBrokeragePhone] = useState<string>("");
  const [directPhone, setDirectPhone] = useState<string>("");
  const [brokerageFax, setBrokerageFax] = useState<string>("");
  const [streetAddress, setStreetAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [province, setProvince] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [recoNumber, setRecoNumber] = useState<string>("");

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [disableVerify, setDisableVerify] = useState<boolean>(false);

  const storeDispatch = useAppDispatch();
  useProjectsQuery(user!.projectAccess!.map((access: any) => access.project._id));

  // Queries/Mutations

  useQuery(GETSMSUSERS, {
    fetchPolicy: "network-only",
    variables: { filter: { receiveSms: true }, page: pageNumber, perPage: 15, sort: "_ID_DESC" },
    onCompleted: (data) => {
      setSmsUsers(data.userPagination.items);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [getUsers, { loading, refetch: refetchGetUsers }] = useLazyQuery(GETUSERS, {
    onCompleted: (data) => {
      storeDispatch(setUsers(data.userPagination));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [sendResetPassword] = useMutation(EMAILPASSWORDRESET, {
    variables: { email: email },
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar("Reset Password Link has been sent!"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [getUser] = useLazyQuery(GETUSER, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setUserId(data.userById._id);
      setFirstName(data.userById.firstName);
      setLastName(data.userById.lastName);
      setEmail(data.userById.email);
      setPrimaryPhone(data.userById.primaryPhone);
      setType(data.userById.type);
      setLocked(data.userById.locked);
      setReceiveSms(data.userById.receiveSms);
      setIsActive(data.userById.isActive);
      setVerified(data.userById.verified);
      if (data.userById.realtor) {
        setRealtorId(data.userById.realtor._id);
        setBrokerage(data.userById.realtor.brokerage);
        setStreetAddress(data.userById.realtor.streetAddress);
        setProvince(data.userById.realtor.province);
        setCity(data.userById.realtor.city);
        setCountry(data.userById.realtor.country);
        setBrokerageFax(data.userById.realtor.brokerageFax);
        setDirectPhone(data.userById.realtor.directPhone);
        setRecoNumber(data.userById.realtor.recoNumber);
        setBrokeragePhone(data.userById.realtor.brokeragePhone);
        setPostalCode(data.userById.realtor.postalCode);
      } else {
        setRealtorId("");
        setBrokerage("");
        setStreetAddress("");
        setProvince("");
        setCity("");
        setCountry("");
        setBrokerageFax("");
        setDirectPhone("");
        setRecoNumber("");
        setBrokeragePhone("");
        setPostalCode("");
      }
      dispatch({ type: "GET", payload: data.userById.projectAccess });
    },
    onError: (err) => {
      console.log(err, "error");
    },
  });

  const [createUser] = useMutation(CREATEUSER, {
    onCompleted: (data) => {
      setView("all");
      setFirstName("");
      setLastName("");
      setEmail("");
      setPrimaryPhone("");
      setPassword("");
      setType("");
      dispatch({ type: "REMOVEALL", payload: [] });
      storeDispatch(addUser(data.userCreateOne.record));
      storeDispatch(showSuccessSnackbar("User Created!"));
    },
    onError: (err: any) => {
      if (err.message.includes("duplicate")) {
        storeDispatch(showErrorSnackbar("Duplicate Email Found"));
      }
    },
  });

  const [updateUserId] = useMutation(UPDATEUSER, {
    onCompleted: (data) => {
      setView("all");
      setFirstName("");
      setLastName("");
      setEmail("");
      setPrimaryPhone("");
      setPassword("");
      setType("");
      if (data.userUpdateById.record.receiveSms) {
        setSmsUsers([...smsUsers, { fullName: data.userUpdateById.record.fullName }]);
      } else {
        setSmsUsers(smsUsers.filter((user) => user.fullName !== data.userUpdateById.record.fullName));
      }
      dispatch({ type: "REMOVEALL", payload: [] });
      storeDispatch(updateUser({ ...data.userUpdateById.record, projectAccess: state }));
      storeDispatch(showSuccessSnackbar("User Updated!"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [updateRealtorUser] = useMutation(UPDATEREALTOR, {
    onCompleted: (data) => {
      setRealtorId("");
      setBrokerage("");
      setStreetAddress("");
      setProvince("");
      setCity("");
      setCountry("");
      setBrokerageFax("");
      setDirectPhone("");
      setRecoNumber("");
      setBrokeragePhone("");
      setPostalCode("");
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [createRealtorUser] = useMutation(CREATEREALTORUSER, {
    onCompleted: (data) => {
      setView("all");
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setType("");
      dispatch({ type: "REMOVEALL", payload: [] });
      storeDispatch(addUser(data.createRealtorUser));
      storeDispatch(showSuccessSnackbar("Realtor Created!"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [verifyUser] = useMutation(VERIFYUSER, {
    onCompleted: () => {
      storeDispatch(showSuccessSnackbar("The user has been verified!"));
    },
    onError: (e) => console.log(e),
  });

  useEffect(() => {
    getUsers({ variables: { page: pageNumber, perPage: 15, sort: "_ID_DESC" } });
  }, [pageNumber, getUsers]);

  // Table Columns

  const columns = useMemo(() => {
    const handleUser = (id: string | undefined) => {
      if (id) {
        setView("edit");
        getUser({ variables: { _id: id } });
      }
    };

    return [
      {
        Header: "First Name",
        accessor: (rowData: IUser) => {
          return (
            <strong style={{ cursor: "pointer" }} onClick={() => handleUser(rowData._id)}>
              {rowData.firstName}
            </strong>
          );
        },
      },
      {
        Header: "Last Name",
        accessor: (rowData: IUser) => {
          return (
            <strong style={{ cursor: "pointer" }} onClick={() => handleUser(rowData._id)}>
              {rowData.lastName}
            </strong>
          );
        },
      },
      {
        Header: "Email",
        accessor: (rowData: IUser) => {
          return rowData.email;
        },
      },
      {
        Header: "User Type",
        accessor: (rowData: IUser) => {
          return rowData.type;
        },
      },
      {
        Header: "Verified",
        accessor: (rowData: IUser) => {
          return rowData.verified ? <span style={{ color: "green" }}>Yes</span> : <span style={{ color: "red" }}>No</span>;
        },
      },
      {
        Header: "Register Date",
        accessor: (rowData: IUser) => {
          if (rowData.createdAt) {
            return convertAllDates(rowData.createdAt, "PPpp");
          } else return "-";
        },
      },
      {
        Header: "Disabled",
        accessor: (rowData: IUser) => {
          return rowData.locked ? <span style={{ color: "red" }}>Yes</span> : <span style={{ color: "green" }}>No</span>;
        },
      },
    ];
  }, [getUser]);

  // Permissions Table

  const permissionColumns = useMemo(
    () => [
      {
        Header: "Project",
        accessor: (rowData: IProjectAccess) => {
          return <strong>{rowData.project.name}</strong>;
        },
      },
      {
        Header: "Create Deal",
        accessor: (rowData: IProjectAccess) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={rowData.access.includes("createDeal")}
                  name={rowData.project._id}
                  value={"createDeal"}
                  onChange={handleAccess}
                />
              }
              label={""}
            />
          );
        },
      },
      {
        Header: "Create Units",
        accessor: (rowData: IProjectAccess) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={rowData.access.includes("createUnits")}
                  name={rowData.project._id}
                  value={"createUnits"}
                  onChange={handleAccess}
                />
              }
              label={""}
            />
          );
        },
      },
      {
        Header: "Edit Units",
        accessor: (rowData: IProjectAccess) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={rowData.access.includes("editUnits")}
                  name={rowData.project._id}
                  value={"editUnits"}
                  onChange={handleAccess}
                />
              }
              label={""}
            />
          );
        },
      },
      {
        Header: "Edit Deals",
        accessor: (rowData: IProjectAccess) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={rowData.access.includes("editDeals")}
                  name={rowData.project._id}
                  value={"editDeals"}
                  onChange={handleAccess}
                />
              }
              label={""}
            />
          );
        },
      },
      {
        Header: "Send Amendments",
        accessor: (rowData: IProjectAccess) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={rowData.access.includes("sendAmendments")}
                  name={rowData.project._id}
                  value={"sendAmendments"}
                  onChange={handleAccess}
                />
              }
              label={""}
            />
          );
        },
      },
      {
        Header: "Create Merges",
        accessor: (rowData: IProjectAccess) => {
          return (
            <FormControlLabel
              checked={rowData.access.includes("merges")}
              control={<Checkbox name={rowData.project._id} value={"merges"} onChange={handleAccess} />}
              label={""}
            />
          );
        },
      },
      {
        Header: "View Price Grid",
        accessor: (rowData: IProjectAccess) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={rowData.access.includes("priceGrid")}
                  name={rowData.project._id}
                  value={"priceGrid"}
                  onChange={handleAccess}
                />
              }
              label={""}
            />
          );
        },
      },
      {
        Header: "View Outstandings",
        accessor: (rowData: IProjectAccess) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={rowData.access.includes("viewOutstandings")}
                  name={rowData.project._id}
                  value={"viewOutstandings"}
                  onChange={handleAccess}
                />
              }
              label={""}
            />
          );
        },
      },
      {
        Header: "View Appointments",
        accessor: (rowData: IProjectAccess) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={rowData.access.includes("appointments")}
                  name={rowData.project._id}
                  value={"appointments"}
                  onChange={handleAccess}
                />
              }
              label={""}
            />
          );
        },
      },
      {
        Header: "View Activities",
        accessor: (rowData: IProjectAccess) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={rowData.access.includes("viewActivities")}
                  name={rowData.project._id}
                  value={"viewActivities"}
                  onChange={handleAccess}
                />
              }
              label={""}
            />
          );
        },
      },
      {
        Header: "View Reports",
        accessor: (rowData: IProjectAccess) => {
          return (
            <FormControlLabel
              checked={rowData.access.includes("viewReports")}
              control={<Checkbox name={rowData.project._id} value={"viewReports"} onChange={handleAccess} />}
              label={""}
            />
          );
        },
      },
      {
        Header: "View Project Settings",
        accessor: (rowData: IProjectAccess) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={rowData.access.includes("projectSettings")}
                  name={rowData.project._id}
                  value={"projectSettings"}
                  onChange={handleAccess}
                />
              }
              label={""}
            />
          );
        },
      },
      {
        Header: "View All Units",
        accessor: (rowData: IProjectAccess) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={rowData.access.includes("viewAllUnits")}
                  name={rowData.project._id}
                  value={"viewAllUnits"}
                  onChange={handleAccess}
                />
              }
              label={""}
            />
          );
        },
      },
      {
        Header: "View Leasing",
        accessor: (rowData: IProjectAccess) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={rowData.access.includes("viewLeasing")}
                  name={rowData.project._id}
                  value={"viewLeasing"}
                  onChange={handleAccess}
                />
              }
              label={""}
            />
          );
        },
      },
    ],
    []
  );

  const handleGlobalFilterValue = (value: string) => {
    setPageNumber(1);
    getUsers({ variables: { filter: { search: value }, page: 1, perPage: 15, sort: "USERTYPE" } });
  };

  const handleProjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch({ type: "ADD", payload: { _id: event.target.id, name: event.target.value } });
    } else {
      dispatch({ type: "DELETE", payload: event.target.id });
    }
  };

  const handleAccess = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch({ type: "ADDACCESS", payload: { _id: event.target.name, value: event.target.value } });
    } else {
      dispatch({ type: "DELETEACCESS", payload: { _id: event.target.name, value: event.target.value } });
    }
  };

  const handleView = (view: string) => {
    setUserId("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setType("");
    setBrokerage("");
    setStreetAddress("");
    setProvince("");
    setCity("");
    setCountry("");
    setBrokerageFax("");
    setDirectPhone("");
    setRecoNumber("");
    setBrokeragePhone("");
    setPostalCode("");
    dispatch({ type: "REMOVEALL", payload: [] });
    setView(view);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (error) return;
    let updatedState = state;

    updatedState = state.map((state: IProjectAccess) => {
      return {
        ...state,
        project: state.project._id,
      };
    });

    let phone = "";
    if (primaryPhone) {
      phone = primaryPhone.toString().replace(/\D/g, "");
    }

    if (view === "add") {
      if (type === "Realtor") {
        createRealtorUser({
          variables: {
            realtor: {
              email,
              firstName,
              lastName,
              brokerage,
              streetAddress,
              city,
              province,
              country,
              postalCode,
              brokeragePhone,
              brokerageFax,
              directPhone,
              recoNumber,
            },
          },
        });
      } else {
        createUser({
          variables: {
            record: {
              firstName,
              lastName,
              email,
              password,
              primaryPhone: parseInt(phone, 10),
              type,
              verified: type === "Realtor" ? false : true,
              fullAccess: type === "Manager" ? true : false,
              projectAccess: updatedState,
            },
          },
        });
      }
    } else if (view === "edit") {
      updateUserId({
        variables: {
          _id: userId,
          record: locked
            ? {
                firstName,
                lastName,
                email,
                primaryPhone: parseInt(phone, 10),
                type,
                verified: verified,
                fullAccess: type === "Manager" ? true : false,
                projectAccess: updatedState,
                isActive: isActive,
                locked: locked,
                receiveSms: type === "Realtor" || type === "Developer" ? false : receiveSms,
              }
            : {
                firstName,
                lastName,
                email,
                type,
                primaryPhone: parseInt(phone, 10),
                verified: verified,
                fullAccess: type === "Manager" ? true : false,
                projectAccess: updatedState,
                isActive: isActive,
                locked: locked,
                loginAttempts: [],
                receiveSms: type === "Realtor" || type === "Developer" ? false : receiveSms,
              },
        },
      });
      if (type === "Realtor") {
        updateRealtorUser({
          variables: {
            _id: realtorId,
            record: {
              brokerage,
              streetAddress,
              city,
              province,
              country,
              postalCode,
              brokeragePhone,
              brokerageFax,
              directPhone,
              recoNumber,
            },
          },
        });
      }
    }
  };

  return (
    <SettingContainer>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: { md: "space-between" } }}>
        <Typography variant="h2" component="div" gutterBottom>
          <strong>{view === "all" ? "Users" : view === "edit" ? "Edit User" : "Add User"}</strong>
        </Typography>
        {view === "all" ? (
          <Button sx={{ width: "max-content" }} onClick={() => handleView("add")} color="primary" variant="contained">
            Create New User
          </Button>
        ) : (
          <Box>
            {!verified && view !== "add" && (
              <Button
                sx={{ mr: 2, mb: 2 }}
                variant="contained"
                color="success"
                disabled={disableVerify}
                onClick={async () => {
                  setDisableVerify(true);
                  await verifyUser({ variables: { user: [userId] } });
                  setDisableVerify(false);
                  await refetchGetUsers({ page: pageNumber, perPage: 15, sort: "_ID_DESC" });
                  handleView("all");
                }}
              >
                Verify
              </Button>
            )}
            <Button sx={{ mr: 2, mb: 2 }} onClick={() => sendResetPassword()} color="primary" variant="contained">
              Send Reset Password Link
            </Button>
            <Button sx={{ mb: 2 }} onClick={() => handleView("all")} color="primary" variant="contained">
              Back
            </Button>
          </Box>
        )}
      </Box>
      {view === "add" || view === "edit" ? (
        // Edit Add //
        <div>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <AccountForm
                type={type}
                setType={setType}
                view={view}
                locked={locked}
                setLocked={setLocked}
                isActive={isActive}
                setIsActive={setIsActive}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                email={email}
                setEmail={setEmail}
                primaryPhone={primaryPhone}
                setPrimaryPhone={setPrimaryPhone}
                userId={userId}
                password={password}
                setPassword={setPassword}
                receiveSms={receiveSms}
                setReceiveSms={setReceiveSms}
                error={error}
                setError={setError}
              />
            </Grid>
            {type === "Realtor" ? (
              <RealtorForm
                brokerage={brokerage}
                setBrokerage={setBrokerage}
                brokeragePhone={brokeragePhone}
                setBrokeragePhone={setBrokeragePhone}
                directPhone={directPhone}
                setDirectPhone={setDirectPhone}
                brokerageFax={brokerageFax}
                setBrokerageFax={setBrokerageFax}
                streetAddress={streetAddress}
                setStreetAddress={setStreetAddress}
                city={city}
                setCity={setCity}
                province={province}
                setProvince={setProvince}
                postalCode={postalCode}
                setPostalCode={setPostalCode}
                country={country}
                setCountry={setCountry}
                recoNumber={recoNumber}
                setRecoNumber={setRecoNumber}
              />
            ) : null}
            {type !== "Realtor" && user?.type === "Manager" ? (
              <>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h3" component="div" gutterBottom>
                    <strong>Project Access</strong>
                  </Typography>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    {projects.map((project: IProject, index: number) => {
                      return (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={state.some((state: IProjectAccess) => state.project._id === project._id)}
                              id={project._id}
                              value={project.name}
                              onChange={handleProjectChange}
                            />
                          }
                          label={project.name}
                        />
                      );
                    })}
                  </Box>
                </Box>
                {state.length ? (
                  <Box sx={{ mt: 2 }}>
                    <StandardTable data={state} columns={permissionColumns} loading={loading} user={user} />
                  </Box>
                ) : null}
              </>
            ) : null}
            <Button sx={{ mt: 2 }} color="success" variant="contained" type="submit">
              {userId ? "Update" : "Create"}
            </Button>
          </form>
        </div>
      ) : (
        <Box sx={{ mt: 2 }}>
          {smsUsers.length ? (
            <Box sx={{ mb: 2 }}>Users receiving SMS Responses: {smsUsers.map((user: IUser) => user.fullName).join(", ")}</Box>
          ) : null}
          <StandardTable
            data={users}
            columns={columns}
            loading={loading}
            handleGlobalFilterValue={handleGlobalFilterValue}
            count={count}
            user={user}
          />
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
              {"<"}
            </Button>
            <span>{pageNumber}</span>
            <Button disabled={pageNumber === Math.ceil(count / 15)} onClick={() => setPageNumber(pageNumber + 1)}>
              {">"}
            </Button>
          </Box>
        </Box>
      )}
    </SettingContainer>
  );
};

const GETUSERS = gql`
  query userPagination($filter: FilterFindManyUserInput, $page: Int!, $perPage: Int!, $sort: SortFindManyUserInput) {
    userPagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        firstName
        lastName
        email
        type
        locked
        verified
        projectAccess {
          project {
            name
          }
        }
        createdAt
      }
      count
    }
  }
`;

const GETSMSUSERS = gql`
  query userPagination($filter: FilterFindManyUserInput, $page: Int!, $perPage: Int!, $sort: SortFindManyUserInput) {
    userPagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        fullName
      }
      count
    }
  }
`;

const GETUSER = gql`
  query userById($_id: MongoID!) {
    userById(_id: $_id) {
      _id
      firstName
      lastName
      email
      primaryPhone
      type
      locked
      isActive
      verified
      receiveSms
      realtor {
        _id
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        brokerageFax
        directPhone
        recoNumber
      }
      projectAccess {
        access
        project {
          _id
          name
        }
      }
      createdAt
    }
  }
`;

const CREATEUSER = gql`
  mutation userCreateOne($record: CreateOneUserInput!) {
    userCreateOne(record: $record) {
      record {
        _id
        firstName
        lastName
        email
        primaryPhone
        type
        locked
        projectAccess {
          project {
            _id
            name
          }
          access
        }
        verified
        createdAt
      }
    }
  }
`;

const UPDATEUSER = gql`
  mutation userUpdateById($_id: MongoID!, $record: UpdateByIdUserInput!) {
    userUpdateById(_id: $_id, record: $record) {
      record {
        _id
        firstName
        lastName
        fullName
        email
        primaryPhone
        type
        locked
        receiveSms
        projectAccess {
          project {
            _id
            name
          }
          access
        }
        verified
        createdAt
      }
    }
  }
`;

const UPDATEREALTOR = gql`
  mutation realtorUpdateById($_id: MongoID!, $record: UpdateByIdRealtorInput!) {
    realtorUpdateById(_id: $_id, record: $record) {
      record {
        _id
        email
        firstName
        lastName
        streetAddress
        brokerage
        brokerageFax
        brokeragePhone
        city
        province
        postalCode
        directPhone
      }
    }
  }
`;

const CREATEREALTORUSER = gql`
  mutation createRealtorUser($realtor: NewUserRealtorInput!, $project: MongoID) {
    createRealtorUser(realtor: $realtor, project: $project) {
      _id
      firstName
      lastName
      email
      type
      locked
      verified
      createdAt
    }
  }
`;

const VERIFYUSER = gql`
  mutation verifyUser($user: [MongoID!]) {
    verifyUsers(userIds: $user)
  }
`;

const EMAILPASSWORDRESET = gql`
  mutation userEmailResetPassword($email: String!) {
    userEmailResetPassword(email: $email)
  }
`;

export default Users;
