import { useState } from "react";
import { Typography, Box, TextField, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";

import { allTokenValues } from "../../../utils/constant";
import { FlexEnd } from "../../../commonStyles";
import { useAppDispatch } from "../../../app/hooks";

const AutoSmsTemplate = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { title, subTitle, setSms, sms, handleTestSms } = props;
  const [textValue, setTextValue] = useState<string>("");

  const handleInsertText = (e: any) => {
    let textToInsert = textValue;
    let cursorPosition = e.target.selectionStart;
    let textBeforeCursorPosition = e.target.value.substring(0, cursorPosition);
    let textAfterCursorPosition = e.target.value.substring(cursorPosition, e.target.value.length);
    e.target.value = textBeforeCursorPosition + textToInsert + textAfterCursorPosition;
    storeDispatch(setSms(e.target.value));
    setTextValue("");
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Box>
        <Typography variant="h5">
          <strong>{title}</strong>
        </Typography>
        <Box>{subTitle}</Box>
      </Box>
      <Box>
        <FormControl fullWidth sx={{ my: 1 }}>
          <InputLabel>Token</InputLabel>
          <Select name={"textValue"} label={"Token"} value={textValue} onChange={(e) => setTextValue(e.target.value)}>
            {allTokenValues.map((value: string, index: number) => {
              return (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {textValue ? (
          <Box sx={{ my: 0.5 }}>
            <em>Please click where you would like to place the token</em>
          </Box>
        ) : null}
      </Box>
      <Box>
        <TextField
          title={"SMS"}
          name={"sms"}
          placeholder={title}
          fullWidth
          multiline
          rows={5}
          value={sms}
          onClick={(e) => {
            handleInsertText(e);
          }}
          onChange={(e) => {
            storeDispatch(setSms(e.target.value));
          }}
        />
      </Box>
      <FlexEnd sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" onClick={() => handleTestSms(sms)}>
          Send Test Sms
        </Button>
      </FlexEnd>
    </Box>
  );
};

interface ChildProps {
  title: string;
  subTitle: string;
  sms: string;
  setSms: any;
  handleTestSms: (text: string) => void;
}

export default AutoSmsTemplate;
