import { useState, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Typography, Box, Button, CircularProgress } from "@mui/material";

import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { IText } from "../../types/callRail";
import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar } from "../../features/snackbar/snackbarSlice";
import { selectFilterProjects, setFilterProjects } from "../../features/project/projectSlice";
import AllTexts from "./AllTexts";
import TextFilter from "./TextFilter";
import { handleModal } from "../../features/modal/modalSlice";

const Sms = () => {
  const user = useSelector(selectUser);
  const filterProjects = useSelector(selectFilterProjects);
  const dispatch = useAppDispatch();
  if (filterProjects.length) dispatch(setFilterProjects([]));
  const storeDispatch = useAppDispatch();
  const [texts, setTexts] = useState<IText[]>([]);
  const [textMessages, setTextMessages] = useState<IText[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [projects, setProjects] = useState<any[]>(filterProjects);
  const [totalTexts, setTotalTexts] = useState<number>(0);

  // Texts
  const [lastReply, setLastReply] = useState<string>("Registrant");
  const [userType, setUserType] = useState<string>("All");
  const [messageType, setMessageType] = useState<string>("Unarchived");

  const [getTextMessages, { loading: textLoading }] = useLazyQuery(TEXTMESSAGES, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      let textMessage = data.getTextMessageGroup.texts;
      if (data.getTextMessageGroup.texts.length) {
        textMessage = data.getTextMessageGroup.texts.map((group: any) => {
          return {
            messages: group.messages.map((text: any) => {
              return {
                content: text.content,
                direction: text.direction,
                created_at: text.createdAt,
                registrant: group._id,
                user: text.user,
                archived: text.archived,
              };
            }),
            lastMessage: group.messages[group.messages.length - 1].createdAt,
            name: group.project.name,
            phoneNumber: group._id.primaryPhone,
          };
        });
      }
      setTotalTexts(data.getTextMessageGroup.count);
      setTextMessages(textMessage);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error getting texts"));
    },
  });

  useEffect(() => {
    getTextMessages({
      variables: { user: userType !== "All" ? user?._id : null, projects, page: pageNumber + 1, messageType, lastReply },
    });
  }, [pageNumber, projects, getTextMessages, userType, user?._id, messageType, lastReply]);

  const addTexts = (data: any) => {
    let formattedPhone = data.phoneNumber.replace(/\D/g, "");
    if (formattedPhone.length === 11) {
      formattedPhone = formattedPhone.slice(1);
    }
    if (textMessages.length) {
      let textMessageArray = textMessages.map((text: IText) => {
        if (text.phoneNumber.replace(/\D/g, "") === formattedPhone && data.name === text.name) {
          return {
            ...text,
            messages: [
              ...text.messages,
              {
                content: data.messages[data.messages.length - 1].content,
                direction: data.messages[data.messages.length - 1].direction,
                created_at: data.messages[data.messages.length - 1].created_at,
                registrant: text.messages[0].registrant ? text.messages[0].registrant : null,
                user: user,
              },
            ],
          };
        } else return text;
      });
      setTextMessages(textMessageArray);
    }
    let newText = texts.map((text: IText) => {
      if (text.phoneNumber === data.phoneNumber) {
        return data;
      } else return text;
    });
    setTexts(newText);
    storeDispatch(handleModal(false));
  };

  return (
    <Box>
      <Typography variant="h2" component="div" gutterBottom>
        <strong>SMS</strong>
      </Typography>
      <TextFilter
        projects={projects}
        setProjects={setProjects}
        setPageNumber={setPageNumber}
        userType={userType}
        setUserType={setUserType}
        lastReply={lastReply}
        setLastReply={setLastReply}
        messageType={messageType}
        setMessageType={setMessageType}
      />
      <Box sx={{ mt: 2 }}>
        <AllTexts textMessages={textMessages} handleTexts={addTexts} setTextMessages={setTextMessages} loading={textLoading} />
      </Box>
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Button
          disabled={pageNumber === 0}
          onClick={() => {
            setPageNumber((pageNumber) => pageNumber - 1);
          }}
        >
          {"<"}
        </Button>
        <span>{pageNumber + 1}</span>
        <Button
          disabled={pageNumber + 1 === Math.ceil(totalTexts / 12) || !totalTexts}
          onClick={() => {
            setPageNumber((pageNumber) => pageNumber + 1);
          }}
        >
          {">"}
        </Button>
      </Box>
    </Box>
  );
};

const TEXTMESSAGES = gql`
  query getTextMessageGroup($user: MongoID, $projects: [String], $page: Float!, $messageType: String, $lastReply: String) {
    getTextMessageGroup(user: $user, projects: $projects, page: $page, messageType: $messageType, lastReply: $lastReply) {
      count
      texts {
        _id {
          _id
          firstName
          lastName
          fullName
          primaryPhone
          project {
            name
            _id
          }
          textUnsubscribed {
            type
            date
          }
        }
        project {
          _id
          name
        }
        messages {
          content
          direction
          user {
            fullName
          }
          createdAt
          archived
        }
      }
    }
  }
`;

export default Sms;
