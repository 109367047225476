import { useQuery, gql } from "@apollo/client";

import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar } from "../snackbar/snackbarSlice";
import {
  setRegistrant,
  setQuestions,
  setRegistrants,
  setCalls,
  setTexts,
  setSalesQuestions,
  setActiveProcesses,
} from "./registrantsSlice";
import { IProcess } from "../../types/process";

export const useRegistrantQuery = (id: string) => {
  const dispatch = useAppDispatch();

  return useQuery(GETREGISTRANT, {
    variables: { _id: id },
    onCompleted: (data) => {
      dispatch(setRegistrant(data.registrantById));
      let currentProcesses = data.registrantById.process.filter((process: IProcess) => process?.currentProcess);
      dispatch(setActiveProcesses(currentProcesses));
      dispatch(setQuestions(data.registrantById.questions));
      dispatch(setSalesQuestions(data.registrantById.salesQuestions));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

export const usePhoneQuery = (id: string, type: string, userType: string) => {
  const dispatch = useAppDispatch();

  return useQuery(GETPHONEDETAILS, {
    skip: userType === "Developer" || !id,
    variables: { registrant: id, type: type },
    onCompleted: (data) => {
      dispatch(setCalls(data.callRailRegistrant.calls));
      dispatch(setTexts(data.callRailRegistrant.texts));
    },
    onError: (e) => {
      console.log(e);
    },
  });
};

export const useRegistrantsQuery = (email: string, primaryPhone: string, projectId: string, user: any) => {
  const dispatch = useAppDispatch();

  let filterQuery;

  if (email) {
    filterQuery = {
      email: email,
      projectIds: user?.projectAccess.map((access: any) => access.project._id),
    };
  } else if (primaryPhone) {
    filterQuery = {
      primaryPhone: primaryPhone,
      projectIds: user?.projectAccess.map((access: any) => access.project._id),
    };
  }

  return useQuery(GETREGISTRANTS, {
    skip: !email && !primaryPhone,
    variables: {
      filter: filterQuery,
    },
    onCompleted: (data) => {
      dispatch(setRegistrants(data.registrantMany));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

const GETREGISTRANT = gql`
  query registrantById($_id: MongoID!) {
    registrantById(_id: $_id) {
      _id
      firstName
      lastName
      alias
      fullName
      email
      primaryPhone
      postalCode
      realtorName
      realtorEmail
      realtorType
      relationships {
        type
        registrant {
          _id
          firstName
          lastName
          email
          relationships {
            type
            registrant {
              _id
            }
          }
          project {
            _id
          }
        }
      }
      project {
        _id
        name
        tagLine
        developerName
        logoGetUrl
      }
      rating
      ethnicity
      statuses {
        name
        source
        questions {
          questionId {
            _id
            question
          }
          answer
        }
        createdAt
      }
      salesRep {
        _id
        firstName
        lastName
        fullName
      }
      onlineSalesRep {
        _id
        firstName
        lastName
        fullName
      }
      questions {
        answer
        questionId {
          _id
          name
          question
          type
        }
      }
      salesQuestions {
        answer
        questionId {
          _id
          name
          question
          type
        }
      }
      mail {
        _id
      }
      process {
        _id
        currentProcess
        viewType
        default
        steps {
          _id
          completed
          default
          dueDate
          type
          emailTemplate {
            name
            _id
          }
          user {
            _id
            fullName
          }
          name
        }
        users {
          _id
          fullName
        }
        timeBetweenSteps
        processType
        name
      }
      emailUnsubscribed {
        type
        date
        schedule {
          emailTemplate {
            name
          }
        }
      }
      textUnsubscribed {
        type
        date
        schedule {
          name
        }
      }
      connections {
        date
        user {
          _id
          fullName
        }
      }
      tags
      count {
        createdAt
        reason
        guests
        scheduleWithRealtor
        meetingWithRealtor
      }
      createdAt
      source
      appointments {
        _id
        project {
          _id
          salesOffice
          name
        }
        user {
          fullName
          email
          realtor {
            _id
            fullName
            directPhone
          }
        }
        date
        location
        purchaserInfo {
          firstName
          lastName
          email
          primaryPhone
          numberOfGuests
        }
        questions {
          questionId {
            _id
            name
            question
            type
            choices {
              choice
              followUp {
                _id
              }
            }
          }
          answer
        }
        status
        type
        notes
        confirmed
        cancelled
        cameIn
        noResponse
        salesNotes
        salesRep {
          _id
          fullName
        }
        registrant {
          _id
          email
          rating
          source
          project {
            _id
          }
          source
          statuses {
            name
            source
            questions {
              questionId {
                _id
                question
              }
              answer
            }
            createdAt
          }
          tags
        }
        createdAt
      }
    }
  }
`;

const GETREGISTRANTS = gql`
  query registrantMany($filter: FilterFindManyRegistrantInput) {
    registrantMany(filter: $filter) {
      _id
      project {
        _id
        name
        portal {
          primaryColor
        }
      }
    }
  }
`;

const GETPHONEDETAILS = gql`
  query callRailRegistrant($registrant: MongoID, $dateStart: Date, $dateEnd: Date, $type: String!) {
    callRailRegistrant(registrant: $registrant, dateStart: $dateStart, dateEnd: $dateEnd, type: $type) {
      calls {
        answered
        customer_city
        direction
        recording
        recording_duration
        recording_player
        business_phone_number
        start_time
        voicemail
        duration
      }
      texts {
        messages {
          direction
          content
          created_at
        }
        lastMessage
        phoneNumber
        name
      }
    }
  }
`;
