import React from "react";
import { Box } from "@mui/material";
import "./LoadingLogo.css";

const LoadingLogo = () => {
  return (
    <Box className="spinHorizontal">
      <img className="imageRotateHorizontal" src={require("../../assets/rds-gold-logo.png")} alt="RDS" height="150" width="150" />
    </Box>
  );
};

export default LoadingLogo;
