/* Dependency Imports */
import { Outlet, Navigate, useParams } from "react-router-dom";

/* Project Imports */
import { selectUser } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { useProjectQuery } from "../../features/project/projectHooks";
import { selectProject } from "../../features/project/projectSlice";

const ProjectAccess = () => {
  /* Redux */
  const { projectid } = useParams();
  const project = useSelector(selectProject);
  useProjectQuery(projectid!, project?._id!);

  const user = useSelector(selectUser);

  /* States */

  /* Queries/Mutations */

  /* Functions */

  return (
    <>
      {user && user?.projectAccess?.some((projectAccess: any) => projectAccess.project._id === projectid) ? (
        <>
          <Outlet />
        </>
      ) : (
        <Navigate to={"/dashboard"} replace />
      )}
    </>
  );
};

/* Types */

/* GQL */

export default ProjectAccess;
