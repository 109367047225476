import { useState, useMemo, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import { subDays } from "date-fns";

import StandardTable from "../tables/StandardTable";
import { capitalizeFirstLetter, convertAllDates, camelToNormal, downloadExcel, downloadPdf } from "../../utils/function";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { ICall } from "../../types/callRail";
import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar } from "../../features/snackbar/snackbarSlice";
import { IProjectAccess, IUser } from "../../types/user";
import { selectFilterProjects, setFilterProjects } from "../../features/project/projectSlice";
import CallFilter from "./CallFilter";
import { showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";

const Notifications = () => {
  const user = useSelector(selectUser);
  const filterProjects = useSelector(selectFilterProjects);
  const dispatch = useAppDispatch();
  if (filterProjects.length) dispatch(setFilterProjects([]));
  const storeDispatch = useAppDispatch();
  const [calls, setCalls] = useState<ICall[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [dateStart, setDateStart] = useState<Date | null>(subDays(new Date(), 3));
  const [dateEnd, setDateEnd] = useState<Date | null>(new Date());
  const [answerStatus, setAnswerStatus] = useState<string>("");
  const [direction, setDirection] = useState<string>("");
  const [projects, setProjects] = useState<any[]>(filterProjects);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [search, setSearch] = useState<number>(0);
  const [selectedUser, setSelectedUser] = useState<IUser>();

  const [getPhoneDetails, { loading }] = useLazyQuery(GETPHONEDETAILS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setCalls(data.callRailRegistrant.calls);
      setTotalRecords(data.callRailRegistrant.totalCallRecords);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const [callRegistrant] = useLazyQuery(CALLREGISTRANT, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(data.callRegistrant));
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const [downloadAudio] = useLazyQuery(DOWNLOADAUDIO, {
    onCompleted: (data) => {
      const element = document.createElement("a");
      element.href = data.downloadAudio;
      element.target = "_blank";
      element.click();
    },
    onError: (e) => {
      console.log(e);
    },
  });

  useEffect(() => {
    if (user?.type !== "Developer") {
      getPhoneDetails({
        variables: {
          dateStart,
          dateEnd,
          answerStatus,
          direction,
          projects,
          type: "project",
          page: pageNumber + 1,
          perPage: 35,
          user: selectedUser ? selectedUser._id : null,
        },
      });
    }
    // eslint-disable-next-line
  }, [pageNumber, search]);

  const columns = useMemo(() => {
    const handleCall = (data: ICall) => {
      let phone = data.customer_phone_number.replace(/\D/g, "");
      if (phone.length < 10 && phone.length > 11) {
        return storeDispatch(showErrorSnackbar("Only US/Canadian Phone Numbers are allowed"));
      }

      let projectId = user?.projectAccess.find((project: IProjectAccess) => project.project.name === data.name);

      callRegistrant({ variables: { project: projectId?.project._id, customerPhone: parseInt(phone, 10) } });
    };

    return [
      {
        Header: "Project",
        accessor: (rowData: ICall) => rowData.name,
      },
      {
        Header: "RDS User",
        accessor: (rowData: ICall) => (rowData.business_phone_number ? rowData.business_phone_number : ""),
      },
      {
        Header: "Registrant",
        accessor: (rowData: ICall) => {
          if (rowData.registrant) {
            return (
              <Link
                target="_blank"
                style={{ textDecoration: "none", color: "#000" }}
                to={`/dashboard/project/${rowData.registrant.project._id}/registrant/${rowData.registrant._id}`}
              >
                <strong>{rowData.registrant.fullName}</strong>
              </Link>
            );
          }
        },
      },
      {
        Header: "Registrant Phone Number",
        accessor: (rowData: ICall) => rowData.customer_phone_number,
      },
      {
        Header: "Registrant Type",
        accessor: (rowData: ICall) => (rowData.registrant ? camelToNormal(rowData.registrant.realtorType) : ""),
      },
      {
        Header: "Date",
        accessor: (rowData: ICall) => convertAllDates(rowData.start_time, "PPpp"),
      },
      {
        Header: "Answered",
        accessor: (rowData: ICall) => (rowData.answered ? <CheckIcon color="success" /> : <CloseIcon color="error" />),
      },
      {
        Header: "Direction",
        accessor: (rowData: ICall) => capitalizeFirstLetter(rowData.direction),
      },
      {
        Header: "Duration(s)",
        accessor: (rowData: ICall) => rowData.recording_duration,
      },
      {
        Header: "Call",
        accessor: (rowData: ICall) => <PhoneIcon onClick={() => handleCall(rowData)} color="success" sx={{ cursor: "pointer" }} />,
      },
      {
        Header: "Listen",
        accessor: (rowData: ICall) => {
          if (rowData.recording_player) {
            return (
              <Button variant="contained" color="primary" rel="noopener noreferrer" target="_blank" href={rowData.recording_player}>
                Listen
              </Button>
            );
          } else return;
        },
      },
      {
        Header: "Download",
        accessor: (rowData: ICall) => {
          if (rowData.recording) {
            return (
              <Button variant="contained" color="primary" onClick={() => downloadAudio({ variables: { link: rowData.recording } })}>
                Download
              </Button>
            );
          } else return;
        },
      },
    ];
  }, [downloadAudio, callRegistrant, storeDispatch, user?.projectAccess]);

  const download = async (type: string) => {
    let data = await getPhoneDetails({
      variables: {
        dateStart,
        dateEnd,
        answerStatus,
        direction,
        projects,
        type: "project",
        page: pageNumber + 1,
        perPage: 250,
        user: selectedUser ? selectedUser._id : null,
      },
    });

    if (data) {
      let columns = [
        {
          label: "Project",
          id: "project",
        },
        {
          label: "RDS User",
          id: "user",
        },
        {
          label: "Registrant",
          id: "registrant",
        },
        {
          label: "Registrant Phone Number",
          id: "registrantPhone",
        },
        {
          label: "registrantType",
          id: "registrantType",
        },
        {
          label: "Date",
          id: "date",
        },
        {
          label: "Answered",
          id: "answered",
        },
        {
          label: "Direction",
          id: "direction",
        },
        {
          label: "Duration",
          id: "duration",
        },
      ];

      let widths: any = {
        project: 15,
        user: 15,
        registrant: 15,
        registrantPhone: 15,
        registrantType: 15,
        date: 15,
        answered: 15,
        direction: 15,
        duration: 15,
      };

      let pdfWidths: any = {
        project: 200,
        user: 200,
        registrant: 200,
        registrantPhone: 200,
        registrantType: 200,
        date: 200,
        answered: 200,
        direction: 200,
        duration: 200,
      };

      let sheetTitle = "CRM Phone Summary";

      let allData = data.data.callRailRegistrant.calls.map((data: any) => {
        return {
          project: data.name,
          user: data.business_phone_number,
          registrant: data.registrant.fullName,
          registrantPhone: data.customer_phone_number,
          registrantType: camelToNormal(data.registrant.realtorType),
          date: convertAllDates(data.start_time, "PPpp"),
          answered: data.answered,
          direction: data.direction,
          duration: data.recording_duration,
        };
      });

      if (type === "excel") {
        downloadExcel([allData], [columns], [], [[widths]], [sheetTitle], sheetTitle);
      } else {
        downloadPdf([allData], [columns], [], [pdfWidths], [sheetTitle], sheetTitle);
      }
    }
  };

  return (
    <Box>
      <Typography variant="h2" component="div" gutterBottom>
        <strong>Phone Calls</strong>
      </Typography>
      <CallFilter
        dateStart={dateStart}
        setDateStart={setDateStart}
        dateEnd={dateEnd}
        setDateEnd={setDateEnd}
        answerStatus={answerStatus}
        setAnswerStatus={setAnswerStatus}
        direction={direction}
        setDirection={setDirection}
        projects={projects}
        setProjects={setProjects}
        setPageNumber={setPageNumber}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
      <Box sx={{ mt: 2 }}>
        <Button sx={{ mt: { xs: 2, md: 0 } }} color="primary" variant="contained" onClick={() => setSearch(search + 1)}>
          Search
        </Button>
      </Box>
      <Box sx={{ mt: 2 }}>
        <StandardTable data={calls} columns={columns} loading={loading} download={download} user={user} />
      </Box>
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Button
          disabled={pageNumber === 0}
          onClick={() => {
            setPageNumber((pageNumber) => pageNumber - 1);
          }}
        >
          {"<"}
        </Button>
        <span>{pageNumber + 1}</span>
        <Button
          disabled={pageNumber + 1 === Math.ceil(totalRecords / 35) || !totalRecords}
          onClick={() => {
            setPageNumber((pageNumber) => pageNumber + 1);
          }}
        >
          {">"}
        </Button>
      </Box>
    </Box>
  );
};

const GETPHONEDETAILS = gql`
  query callRailRegistrant(
    $registrant: MongoID
    $dateStart: Date
    $dateEnd: Date
    $answerStatus: String
    $direction: String
    $projects: [String]
    $callType: String
    $perPage: Float
    $page: Float!
    $type: String!
    $user: MongoID
  ) {
    callRailRegistrant(
      registrant: $registrant
      dateStart: $dateStart
      dateEnd: $dateEnd
      answerStatus: $answerStatus
      direction: $direction
      projects: $projects
      callType: $callType
      page: $page
      perPage: $perPage
      type: $type
      user: $user
    ) {
      totalCallRecords
      calls {
        name
        customer_phone_number
        business_phone_number
        answered
        customer_city
        direction
        recording
        recording_duration
        recording_player
        start_time
        voicemail
        registrant {
          _id
          fullName
          project {
            _id
          }
          realtorType
        }
      }
      texts {
        lastMessage
        phoneNumber
        messages {
          direction
          content
          created_at
        }
        name
      }
    }
  }
`;

const DOWNLOADAUDIO = gql`
  query downloadAudio($link: String!) {
    downloadAudio(link: $link)
  }
`;

const CALLREGISTRANT = gql`
  query callRegistrant($project: MongoID!, $customerPhone: Float!) {
    callRegistrant(project: $project, customerPhone: $customerPhone)
  }
`;

export default Notifications;
