import { useState, useMemo, Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { gql, useMutation } from "@apollo/client";
import { IText, ITextMessage } from "../../types/callRail";
import StandardTable from "../tables/StandardTable";
import { convertAllDates } from "../../utils/function";
import { GlobalModal } from "../../features/modal/Modal";
import Texts from "../registrant/Texts";
import Archive from "./Archive";
import { useAppDispatch } from "../../app/hooks";
import { handleModal } from "../../features/modal/modalSlice";
import { showErrorSnackbar } from "../../features/snackbar/snackbarSlice";

const AllTexts = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { textMessages, handleTexts, setTextMessages, loading } = props;
  const [modalType, setModalType] = useState<string>("");
  const [selectedTextGroup, setSelectedTextGroup] = useState<IText | null>(null);

  const [archiveAll] = useMutation(ARCHIVE, {
    onCompleted: (data) => {},
    onError: (e) => {
      console.log(e);
    },
  });

  const columns = useMemo(() => {
    return [
      {
        Header: "Registrant",
        accessor: (rowData: any, index: number) => {
          return (
            <Link
              target="_blank"
              style={{ textDecoration: "none", color: "#000" }}
              to={`/dashboard/project/${rowData.messages[0].registrant.project._id}/registrant/${rowData.messages[0].registrant._id}`}
            >
              <strong>{rowData.messages[0].registrant.fullName}</strong>
            </Link>
          );
        },
      },
      {
        Header: "Project",
        accessor: (rowData: any, index: number) => rowData.messages[0].registrant.project.name,
      },
      {
        Header: "Latest Message",
        accessor: (rowData: any, index: number) => {
          return rowData.messages[rowData.messages.length - 1].content;
        },
      },
      {
        Header: "Last Reply",
        accessor: (rowData: any, index: number) => {
          let lastReply;
          let color;
          if (rowData.messages[rowData.messages.length - 1].direction === "inbound") {
            lastReply = `${rowData.messages[rowData.messages.length - 1].registrant.fullName}`;
            color = "red";
          } else {
            if (!rowData.messages[rowData.messages.length - 1].user) {
              lastReply = "Automated";
              color = "orange";
            } else {
              lastReply = `${rowData.messages[rowData.messages.length - 1].user.fullName}`;
              color = "green";
            }
          }
          return <Box sx={{ color: color }}>{lastReply}</Box>;
        },
      },
      {
        Header: "Last Reply Date",
        accessor: (rowData: any, index: number) => {
          return convertAllDates(rowData.messages[rowData.messages.length - 1].created_at, "PPpp");
        },
      },
      {
        Header: "View Conversation",
        accessor: (rowData: any, index: number) => {
          return (
            <Button variant="contained" color="primary" onClick={() => displayModal("text", rowData)}>
              View Conversation
            </Button>
          );
        },
      },
      {
        Header: "Archive",
        accessor: (rowData: any, index: number) => {
          return (
            <Button variant="contained" color="primary" onClick={() => displayModal("archive", rowData)}>
              {rowData.messages.every((message: ITextMessage) => message.archived) ? "Unarchive" : "Archive"}
            </Button>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textMessages, loading]);

  const displayModal = (type: string, text: IText) => {
    setSelectedTextGroup(text);
    setModalType(type);
    storeDispatch(handleModal(true));
  };

  const archiveTexts = () => {
    if (!selectedTextGroup) return storeDispatch(showErrorSnackbar("Cannot Archive"));
    if (!selectedTextGroup.messages[0].registrant) {
      storeDispatch(showErrorSnackbar("Cannot Archive, No Registrant Profile"));
    }

    archiveAll({
      variables: {
        filter: {
          registrant: selectedTextGroup.messages[0].registrant?._id,
        },
        record: {
          archived: selectedTextGroup.messages.every((message: ITextMessage) => message.archived) ? false : true,
        },
      },
    }).then((res) => {
      let filteredTextMessages = textMessages.filter(
        (textMessage: any) => textMessage.messages[0].registrant._id !== selectedTextGroup.messages[0].registrant?._id
      );
      setTextMessages(filteredTextMessages);
      storeDispatch(handleModal(false));
      setSelectedTextGroup(null);
    });
    // storeDispatch(handleModal(false));
  };

  return (
    <div>
      <GlobalModal>
        {modalType === "text" ? <Texts data={selectedTextGroup} handleTexts={handleTexts} /> : null}
        {modalType === "archive" ? <Archive data={selectedTextGroup} archiveTexts={archiveTexts} /> : null}
      </GlobalModal>
      <StandardTable data={textMessages} columns={columns} loading={loading} />
    </div>
  );
};

interface ChildProps {
  textMessages: IText[];
  handleTexts: any;
  setTextMessages: Dispatch<SetStateAction<IText[]>>;
  loading: boolean;
}

const ARCHIVE = gql`
  mutation textMessageUpdateMany($record: UpdateManyTextMessageInput!, $filter: FilterUpdateManyTextMessageInput) {
    textMessageUpdateMany(record: $record, filter: $filter) {
      numAffected
    }
  }
`;

export default AllTexts;
