import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { convertAllDates } from "../../../utils/function";

const TextStats = (props: ChildProps) => {
  const { text } = props;
  const navigate = useNavigate();
  const { projectid } = useParams();

  const handleThresholds = (value: number, type: string) => {
    if (type === "delivered") {
      if (value < 90) return "#c7f5c7";
      else if (value < 50) return "#fdfdbe";
      else return "#f5cccc";
    }
    return "#fff";
  };

  const massTexts = [
    {
      title: "Delivered",
      value: text.sent,
      percentage:
        (text.sent / text.total) *
        100,
      bgColor: handleThresholds(
        (text.sent - text.total) * 100,
        "delivered"
      ),
    },
    {
      title: "Total",
      value: text.total ? text.total : 0,
      percentage: 100,
      bgColor: "#c7f5c7",
    },
    {
      title: "Unsubscribes",
      value: text.unsubscribes ? text.unsubscribes : 0,
      percentage:  (text.unsubscribes / text.total) *
      100,
      bgColor: "#c7f5c7",
    },
  ];

  return (
    <Box sx={{ p: 2 }}>
      <Typography sx={{ mb: 0, textAlign: "center" }} variant="h3" component="div" gutterBottom>
        <strong>{text.textSchedule.name}</strong>
      </Typography>
      <Box sx={{ textAlign: "center", mb: 1 }}>
        <Box>
          <em style={{ fontSize: "11px" }}>{convertAllDates(text.startDate, "PPpp")}</em>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {massTexts.map((text, index) => {
          return (
            <Grid key={index} item xs={12} sm={6}>
              <Box
                sx={{
                  fontSize: "1rem",
                  padding: "0.5rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #000",
                  backgroundColor: text.bgColor,
                  height: "100%",
                }}
              >
                <Box sx={{ fontSize: "24px" }}>{text.percentage.toFixed(2)}%</Box>
                <Box sx={{ fontSize: "10px" }}>
                  {text.title} ({text.value})
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

interface ChildProps {
  text: any;
}
export default TextStats;
