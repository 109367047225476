import { useState, useReducer, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { IRegistrant } from "../../types/registrant";
import { Container, FlexBetween } from "../../commonStyles";
import { Typography, Button, Box } from "@mui/material";
import BookAnAppointmentDialog from "./process/BookAnAppointmentDialog";
import AppointmentsTable from "../appointments/AppointmentsTable";
import { ISchedule, IScheduleMany } from "../../types/schedule";
import { IAppointment } from "../../types/project";

export const appointmentsReducer = (state: any, action: any) => {
  switch (action.type) {
    case "ADD":
      return [...state, action.payload.value];
    case "COPY":
      return [...state, action.payload];
    case "UPDATE":
      return state.map((state: any, index: number) => {
        if (state._id === action.payload._id) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case "UPDATEALL":
      return action.payload;
    case "DELETE":
      return state.filter((state: any, index: number) => state._id !== action.payload._id);
    default:
      throw new Error();
  }
};

const Appointments = (props: ChildProps) => {
  const { registrant } = props;
  const [appointmentsState, appointmentsDispatch] = useReducer(appointmentsReducer, []);
  const [bookAppointmentOpen, setBookAppointmentOpen] = useState<boolean>(false);
  const [selectedAppointment, setSelectedAppointment] = useState<any>(null);
  const [dialogType, setDialogType] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [schedules, setSchedules] = useState<ISchedule[]>([]);
  const [schedule, setSchedule] = useState<ISchedule | null>(null);

  useEffect(() => {
    if (registrant.appointments.length) {
      let appointments = [...registrant.appointments]
        .filter((appointment: IAppointment) => appointment !== null)
        .sort((a: any, b: any) => {
          return new Date(a.date).valueOf() - new Date(b.date).valueOf();
        });
      appointmentsDispatch({
        type: "UPDATEALL",
        payload: appointments,
      });
    }
  }, [registrant]);

  useQuery<IScheduleMany>(GETSCHEDULES, {
    skip: !bookAppointmentOpen,
    variables: {
      filter: { project: registrant.project?._id, active: true },
    },
    onCompleted: (data) => {
      let allSchedules = [...data.scheduleMany].sort(
        (a: any, b: any) =>
          new Date(b.schedules[b.schedules.length - 1].date).valueOf() - new Date(a.schedules[a.schedules.length - 1].date).valueOf()
      );
      setSchedules(allSchedules);
    },
    onError: (error) => {
      console.log(error, "err");
    },
  });

  return (
    <Container>
      <BookAnAppointmentDialog open={bookAppointmentOpen} setOpen={setBookAppointmentOpen} appointmentsDispatch={appointmentsDispatch} />
      <FlexBetween>
        <Typography variant="h2">
          <strong>Appointments</strong>
        </Typography>
        <Button
          sx={{ ml: { xs: 0, md: 2 } }}
          variant="contained"
          onClick={() => {
            setBookAppointmentOpen(true);
          }}
        >
          Book Appointment
        </Button>
      </FlexBetween>
      <Box>
        {appointmentsState.length ? (
          <AppointmentsTable
            appointments={appointmentsState}
            loading={false}
            appointmentsDispatch={appointmentsDispatch}
            schedule={schedule}
            setSchedule={setSchedule}
            schedules={schedules}
            selectedAppointment={selectedAppointment}
            setSelectedAppointment={setSelectedAppointment}
            dialogOpen={dialogOpen}
            dialogType={dialogType}
            setDialogOpen={setDialogOpen}
            setDialogType={setDialogType}
            setSearchFilter={setSearchFilter}
            registrant={registrant}
            selectedRegistrants={[]}
            setSelectedRegistrants={() => {}}
          />
        ) : (
          <Box>There are currently no appointments</Box>
        )}
      </Box>
    </Container>
  );
};

interface ChildProps {
  registrant: IRegistrant;
}

const GETSCHEDULES = gql`
  query scheduleMany($filter: FilterFindManyScheduleInput) {
    scheduleMany(filter: $filter, limit: 10000) {
      _id
      active
      allowGuests
      public
      name
      schedules {
        date
        length
        timeBetweenAppointments
        timeStart
        timeEnd
        numberOfAppointments
      }
      locations
      type
      access
      project {
        _id
        name
      }
    }
  }
`;

export default Appointments;
