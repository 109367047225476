import { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  FormControl,
  Select,
  Button,
  MenuItem,
  Skeleton,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useSearchParams } from "react-router-dom";
import { IRegistrant } from "../../types/registrant";

const Unsubscribe = () => {
  const [unsubscribeValue, setUnsubscribeValue] = useState<string>("project");
  const [searchParams] = useSearchParams();
  const [registrant, setRegistrant] = useState<IRegistrant | null>(null);
  const [unsubscribed, setUnsubscribed] = useState<boolean>(false);
  const token = searchParams.get("token"); // "testCode"
  const schedule = searchParams.get("schedule");

  const { loading } = useQuery(GETREGISTRANT, {
    skip: !token,
    variables: { unsubscribeToken: token },
    onCompleted: (data) => {
      setRegistrant(data.checkRegistrantToken);
    },
    onError: (err) => {
      console.log(err, "error");
    },
  });

  const [unsubscribe] = useMutation(UNSUBSCRIBE, {
    onCompleted: (data) => {
      setUnsubscribed(data.unsubscribeRegistrants);
    },
    onError: (err) => {
      console.log(err, "error");
    },
  });

  const handleChange = (event: SelectChangeEvent) => {
    setUnsubscribeValue((event.target as HTMLInputElement).value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    unsubscribe({ variables: { unsubscribeType: unsubscribeValue, token: token, email: registrant?.email, schedule } });
  };

  return (
    <Box sx={{ backgroundColor: "#00142a", height: "100vh" }}>
      <Box
        sx={{
          position: "fixed",
          inset: 0,
          margin: "auto",
          width: "600px",
          maxWidth: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: 2,
        }}
      >
        <Card sx={{ mb: 2 }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              p: 3,
            }}
          >
            {!loading ? (
              unsubscribed ? (
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="h2" align="center" sx={{ mt: 1, mb: 2 }}>
                    <strong>We're sad to see you go!</strong>
                  </Typography>
                  <p>
                    <em>If you would like to resubscribe, please contact info@rdsre.ca</em>
                  </p>
                </Box>
              ) : registrant ? (
                <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%", justifyContent: "center", textAlign: "left" }}>
                  <Typography variant="h2" align="left" sx={{ mt: 1, mb: 2 }}>
                    <strong>Unsubscribe</strong>
                  </Typography>
                  <p>
                    Your email address is <strong>{registrant?.email}</strong>
                  </p>
                  <p>Are you sure you would like to unsubscribe?</p>
                  <p>Please select your preference below regarding emails from our RDS community specialists:</p>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="demo-simple-select-label">Unsubscribe</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={unsubscribeValue}
                      label="Unsubscribe"
                      onChange={handleChange}
                      required
                    >
                      <MenuItem value={"project"}>
                        I no longer wish to receive promotional emails regarding {registrant?.project.name}
                      </MenuItem>
                      <MenuItem value={"all"}>
                        I no longer wish to receive any communication regarding ownership opportunities from RDS.
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Button sx={{ mt: 2 }} variant="contained" type="submit" color="success">
                    Submit
                  </Button>
                </Box>
              ) : (
                <Box>The link has expired or is invalid. Please contact info@rdsre.ca if you would like to unsubscribe.</Box>
              )
            ) : (
              <div>
                <Skeleton height={100} animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </div>
            )}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

const GETREGISTRANT = gql`
  query checkRegistrantToken($unsubscribeToken: String!) {
    checkRegistrantToken(unsubscribeToken: $unsubscribeToken) {
      _id
      email
      project {
        name
      }
    }
  }
`;

const UNSUBSCRIBE = gql`
  mutation unsubscribeRegistrants($unsubscribeType: String!, $token: String!, $email: String!, $schedule: MongoID!) {
    unsubscribeRegistrants(unsubscribeType: $unsubscribeType, token: $token, email: $email, schedule: $schedule)
  }
`;

export default Unsubscribe;
