import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { Container } from "../../commonStyles";
import { IRegistrant } from "../../types/registrant";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const Mails = ({ registrant }: MailsProps) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [emailCount, setEmailCount] = useState<number>(0);
  const [emails, setEmails] = useState<any[]>([]);

  const emailsPerPage = 5;

  const [getRegistrantMails, { loading }] = useLazyQuery(GETREGISTRANTMAILS, {
    onCompleted: (data) => {
      setPageNumber(pageNumber + 1);
      setEmails((prevEmails) => prevEmails.concat(data.mailPagination.items));
      setEmailCount(data.mailPagination.count);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  return (
    <Container sx={{ height: "100%" }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h2">
          <strong>Mass Mails</strong>
        </Typography>
      </Box>
      {!loading && emails.length > 0 ? (
        <Box>
          {emails.map((email, key) => (
            <Accordion key={key} sx={{ mt: 2, border: "1px solid black", borderRadius: 1 }} elevation={0}>
              <AccordionSummary
                sx={{ borderBottom: "1px solid black" }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ mr: 1 }}>
                    <strong>{email.emailTemplate ? email.emailTemplate.name : "No Name"}</strong> -{" "}
                    {new Date(email.createdAt).toDateString()}
                  </Typography>
                  <Typography
                    sx={
                      (email.status === "opened" && { color: "green" }) ||
                      (email.status === "sent" && { color: "#fcba03" }) ||
                      (email.status === "permanentBounce" && { color: "red" }) ||
                      (email.status === "softBounce" && { color: "#bd8c06" }) ||
                      {}
                    }
                  >
                    [{email.status}]
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={1}>
                  <Typography>
                    <strong>Date Read: </strong>
                    {(email.dateRead && new Date(email.dateRead).toDateString()) || "N/A"}
                  </Typography>
                  <Typography>
                    <strong>Last Clicked: </strong>
                    {email.lastClicked || "N/A"}
                  </Typography>
                  <Typography>
                    <strong>Sender: </strong>
                    {email.mailSchedule ? email.mailSchedule.senderName : ""}
                  </Typography>
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button
              disabled={pageNumber * emailsPerPage >= emailCount}
              onClick={() => {
                setPageNumber(pageNumber + 1);
                getRegistrantMails({
                  variables: { registrantId: registrant._id, page: pageNumber + 1, perPage: emailsPerPage, sort: "DATE_DESC" },
                });
              }}
            >
              <ArrowDownwardIcon />
            </Button>
          </Box>
        </Box>
      ) : pageNumber === 1 ? (
        <Box sx={{ textAlign: "center" }}>
          <Button
            sx={{ textAlign: "center", margin: "0 auto" }}
            variant="contained"
            color="primary"
            onClick={() => {
              getRegistrantMails({
                variables: { registrantId: registrant._id, page: pageNumber + 1, perPage: emailsPerPage, sort: "DATE_DESC" },
              });
            }}
          >
            {loading ? <CircularProgress sx={{ color: "#fff" }} size={20} /> : "View Mass Mail"}
          </Button>
        </Box>
      ) : (
        <Box>There are currently no mail sent to this registrant.</Box>
      )}
    </Container>
  );
};

const GETREGISTRANTMAILS = gql`
  query getRegistrantMails($registrantId: MongoID, $page: Int, $perPage: Int, $sort: SortFindManyMailInput) {
    mailPagination(filter: { registrant: $registrantId }, page: $page, perPage: $perPage, sort: $sort) {
      items {
        status
        dateRead
        lastClicked
        mailSchedule {
          mailDate
          senderName
        }
        emailTemplate {
          name
        }
        createdAt
      }
      count
    }
  }
`;

interface MailsProps {
  registrant: IRegistrant;
}

export default Mails;
