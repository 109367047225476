// @ts-ignore
import { CssBaseline } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@emotion/react";
import { PersistGate } from "redux-persist/integration/react";

// Project Imports
import { store } from "./app/store";
import { Snackbar } from "./features/snackbar/Snackbar";
import { Notifications as NotificationsSnackbar } from "./features/notifications/Notifications";
import RequireAuth from "./features/auth/RequireAuth";
import PublicAuth from "./features/auth/PublicAuth";
import { persistor } from "./app/store";

import { theme } from "./theme";

import Login from "./components/login/Login";
import Dashboard from "./components/dashboard/Dashboard";
import Settings from "./components/settings/Settings";
import UserProfile from "./components/userProfile/UserProfile";
import Project from "./components/project/Project";
import ProjectSettings from "./components/projectSettings/ProjectSettings";
import EmailTemplates from "./components/projectSettings/EmailTemplates";
import Registrant from "./components/registrant/Registrant";
import Layout from "./components/layout/Layout";
import RegistrantSignup from "./components/registrantSignup/RegistrantSignup";
import CreateList from "./components/project/lists/CreateList";
import ListTable from "./components/project/lists/ListTable";
import ScheduleMailing from "./components/miscellaneous/ScheduleMailing";
import Notifications from "./components/notification/Notifications";
import Tracking from "./components/project/tracking/Tracking";
import PrivateRoute from "./components/layout/PrivateRoute";
import MarketingRoute from "./components/layout/MarketingRoute";
import ProjectAccess from "./components/layout/ProjectAccess";
import Unsubscribe from "./components/miscellaneous/Unsubscribe";
import MailingTable from "./components/project/mailing/MailingTable";
import PageNotFound from "./components/miscellaneous/PageNotFound";
import Appointments from "./components/appointments/Appointments";
import UploadRegistrant from "./components/project/UploadRegistrant";
import AllMailingStats from "./components/project/mailing/AllMailingStats";
import AllTextStats from "./components/project/text/AllTextStats";
import ActionTable from "./components/project/actions/ActionTable";
import ProcessTable from "./components/project/scrum/ProcessTable";
import Reporting from "./components/project/report/Reporting";
import SearchPage from "./components/miscellaneous/SearchPage";
import Sms from "./components/sms/Sms";

import { RegistrantFilterProvider } from "./context/RegistrantFilterContext";

const App = (props: any) => {
  // Safari Mobile

  // Create a WebSocket link:

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <NotificationsSnackbar />
            <Snackbar />
            <Routes>
              <Route element={<PublicAuth />}>
                <Route path="/" element={<Login />} />
                <Route path="registrant-signup/:projectid" element={<RegistrantSignup />} />
                <Route path="/unsubscribe" element={<Unsubscribe />} />
              </Route>
              <Route element={<RequireAuth />}>
                <Route element={<Layout />}>
                  <Route
                    path="settings"
                    element={
                      <PrivateRoute redirectPath="/dashboard">
                        <Settings />
                      </PrivateRoute>
                    }
                  />
                  <Route path="user" element={<UserProfile />} />
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="email-templates" element={<EmailTemplates />} />
                  <Route
                    path="dashboard/notifications"
                    element={
                      <PrivateRoute redirectPath="/dashboard">
                        <Notifications />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="dashboard/sms"
                    element={
                      <PrivateRoute redirectPath="/dashboard">
                        <Sms />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="dashboard/scheduled-mailings"
                    element={
                      <MarketingRoute redirectPath="/dashboard">
                        <ScheduleMailing />
                      </MarketingRoute>
                    }
                  />
                  <Route
                    path="dashboard/appointments"
                    element={
                      <MarketingRoute redirectPath="/dashboard">
                        <Appointments />
                      </MarketingRoute>
                    }
                  />
                  <Route element={<RegistrantFilterProvider />}>
                    <Route
                      path="dashboard/reporting"
                      element={
                        <MarketingRoute redirectPath="/dashboard">
                          <Reporting />
                        </MarketingRoute>
                      }
                    />
                  </Route>
                  <Route
                    path="dashboard/search-page"
                    element={
                      <MarketingRoute redirectPath="/dashboard">
                        <SearchPage />
                      </MarketingRoute>
                    }
                  />
                  <Route element={<ProjectAccess />}>
                    <Route
                      element={
                        <MarketingRoute redirectPath="/dashboard">
                          <RegistrantFilterProvider />
                        </MarketingRoute>
                      }
                    >
                      <Route
                        path="dashboard/project/:projectid/registrant/:registrantid"
                        element={
                          <MarketingRoute redirectPath="/dashboard">
                            <Registrant />
                          </MarketingRoute>
                        }
                      />
                      <Route
                        path="dashboard/project/:projectid/list/:listid"
                        element={
                          <MarketingRoute redirectPath="/dashboard">
                            <ListTable />
                          </MarketingRoute>
                        }
                      />
                    </Route>
                    <Route
                      path="dashboard/project/:projectid"
                      element={
                        <MarketingRoute redirectPath="/dashboard">
                          <Project />
                        </MarketingRoute>
                      }
                    />
                    <Route
                      path="dashboard/project/:projectid/settings"
                      element={
                        <PrivateRoute redirectPath="/dashboard">
                          <ProjectSettings />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="dashboard/project/:projectid/create-list"
                      element={
                        <MarketingRoute redirectPath="/dashboard">
                          <CreateList />
                        </MarketingRoute>
                      }
                    />
                    <Route
                      path="dashboard/project/:projectid/edit-list/:listid"
                      element={
                        <MarketingRoute redirectPath="/dashboard">
                          <CreateList />
                        </MarketingRoute>
                      }
                    />
                    <Route
                      path="dashboard/project/:projectid/actions"
                      element={
                        <MarketingRoute redirectPath="/dashboard">
                          <ActionTable />
                        </MarketingRoute>
                      }
                    />
                    <Route
                      path="dashboard/project/:projectid/unassigned-processes"
                      element={
                        <MarketingRoute redirectPath="/dashboard">
                          <ProcessTable />
                        </MarketingRoute>
                      }
                    />
                    <Route
                      path="dashboard/project/:projectid/tracking"
                      element={
                        <MarketingRoute redirectPath="/dashboard">
                          <Tracking />
                        </MarketingRoute>
                      }
                    />
                    <Route element={<RegistrantFilterProvider />}>
                      <Route
                        path="dashboard/project/:projectid/reporting"
                        element={
                          <MarketingRoute redirectPath="/dashboard">
                            <Reporting />
                          </MarketingRoute>
                        }
                      />
                    </Route>
                    <Route
                      path="dashboard/project/:projectid/upload-registrant"
                      element={
                        <MarketingRoute redirectPath="/dashboard">
                          <UploadRegistrant />
                        </MarketingRoute>
                      }
                    />
                    <Route
                      path="dashboard/project/:projectid/mail/:mailid"
                      element={
                        <MarketingRoute redirectPath="/dashboard">
                          <MailingTable />
                        </MarketingRoute>
                      }
                    />
                    <Route
                      path="dashboard/project/:projectid/mail/all-mail"
                      element={
                        <MarketingRoute redirectPath="/dashboard">
                          <AllMailingStats />
                        </MarketingRoute>
                      }
                    />
                    <Route
                      path="dashboard/project/:projectid/text/all-text"
                      element={
                        <MarketingRoute redirectPath="/dashboard">
                          <AllTextStats />
                        </MarketingRoute>
                      }
                    />
                  </Route>
                </Route>
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </CssBaseline>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
