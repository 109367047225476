import { useState, Dispatch, SetStateAction } from "react";
import { gql, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import {
  Typography,
  Box,
  Button,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
  InputLabel,
  ListSubheader,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";

import { IProcessTemplate } from "../../types/process";
import { FlexBetween } from "../../commonStyles";
import { IUser } from "../../types/user";
import { camelToNormal } from "../../utils/function";
import { selectProjectProcesses, selectUsers } from "../../features/projectSetting/projectSettingSlice";
import { useAppDispatch } from "../../app/hooks";
import { handleModal } from "../../features/modal/modalSlice";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";

const ReassignProcesses = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { selectedRegistrants, project, setModalType } = props;
  const processes = useSelector(selectProjectProcesses);
  const users = useSelector(selectUsers);
  const [process, setProcess] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);

  const [reassignProcesses, { loading }] = useMutation(REASSIGNPROCESSES, {
    onCompleted: (data) => {
      setModalType("");
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar("Processes Reassigned!"));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error assigning processes"));
      console.log(err, "err");
    },
  });

  const submitProcess = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!process) return storeDispatch(showErrorSnackbar("No Process Selected"));
    if (!selectedUsers.length) return storeDispatch(showErrorSnackbar("No Users Selected"));

    reassignProcesses({
      variables: {
        processId: process,
        projectId: project,
        registrantIds: selectedRegistrants,
        userIds: selectedUsers.map((user: IUser) => user._id),
      },
    });
  };

  return (
    <form onSubmit={submitProcess}>
      <Typography variant="h2" component="div" gutterBottom>
        <strong>Reassign Processes</strong>
      </Typography>
      <div>
        <p>
          Please select a process below to move <strong>{selectedRegistrants.length}</strong> registrants to
        </p>
        <Box sx={{ mt: 2 }}>
          <FormControl required fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Process</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              required
              value={process}
              label="Process"
              onChange={(e: SelectChangeEvent<string>) => setProcess(e.target.value)}
            >
              {Object.entries(
                processes.reduce(
                  (processGroup: any, process: IProcessTemplate) => {
                    if (process) {
                      const { type } = process;
                      processGroup[type] = processGroup[type] ?? [];
                      processGroup[type].push(process);
                    }
                    return processGroup;
                  },
                  { shortTerm: [], event: [], handover: [], sales: [], longTerm: [] }
                )
              ).map((processGroup: any, i: number) => [
                <ListSubheader key={i}>
                  <strong>{camelToNormal(processGroup[0])}</strong>
                </ListSubheader>,
                processGroup[1].map((process: IProcessTemplate, index: number) => {
                  return (
                    <MenuItem sx={{ pl: 4 }} key={index} value={process._id}>
                      {process.name}
                    </MenuItem>
                  );
                }),
              ])}
            </Select>
          </FormControl>
        </Box>
        <Autocomplete
          multiple
          id="tags-standard"
          options={users}
          freeSolo={false}
          getOptionLabel={(option: any) => option.fullName}
          isOptionEqualToValue={(option, value) => option === value}
          value={selectedUsers}
          onChange={(e: any, value) => setSelectedUsers(value)}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Users Assigned to Process" />}
        />
        <FlexBetween sx={{ mt: 2 }}>
          <Button type="submit" color="success" variant="contained" disabled={loading}>
            {loading ? <CircularProgress size={20} /> : "Update all Registrant Processes"}
          </Button>
          <Button
            onClick={() => {
              setModalType("");
              storeDispatch(handleModal(false));
            }}
            color="info"
            variant="contained"
          >
            Cancel
          </Button>
        </FlexBetween>
      </div>
    </form>
  );
};

const REASSIGNPROCESSES = gql`
  mutation reassignProcesses($processId: MongoID!, $projectId: MongoID!, $registrantIds: [MongoID!], $userIds: [MongoID!]) {
    reassignProcesses(processId: $processId, projectId: $projectId, registrantIds: $registrantIds, userIds: $userIds)
  }
`;

interface ChildProps {
  selectedRegistrants: string[];
  project?: string | null;
  setModalType: Dispatch<SetStateAction<string>>;
}

export default ReassignProcesses;
