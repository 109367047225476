/* Dependency Imports */
import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import {
  Box,
  Link,
  TextField,
  Button,
  InputAdornment,
  InputLabel,
  FormControl,
  OutlinedInput,
  IconButton,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Navigate } from "react-router-dom";

/* Project Imports */
import ForgotPassword from "./ForgotPassword";
import LoadingLogo from "../common/LoadingLogo";
import { useUserQuery, useLogin } from "../../features/auth/authHooks";

const Login = () => {
  /* Redux/Hooks */
  const { loading, data } = useUserQuery(false);
  const client = useApolloClient();
  const login = useLogin();

  /* States */
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  /* Functions */

  useEffect(() => {
    client.resetStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    login(email, password);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setpassword(event.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowForgotPassword = () => {
    setShowForgotPassword(true);
  };

  return loading ? (
    <Box
      sx={{
        position: "absolute",
        left: "50%",
        top: "50%",
        "-webkit-transform": "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
      }}
    >
      <LoadingLogo />
    </Box>
  ) : data?.getUser ? (
    <>
      <Navigate to="/dashboard" />
    </>
  ) : (
    <Box
      sx={{
        background: "rgba(0,0,0,0.5)url(https://rdsimages.s3.ca-central-1.amazonaws.com/cielo.jpg)",
        backgroundBlendMode: "darken",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "100%",
        position: "absolute",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          inset: 0,
          margin: "auto",
          width: "400px",
          maxWidth: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Card sx={{ mb: 2 }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              pt: 3,
            }}
          >
            <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
              <img src="https://s3.ca-central-1.amazonaws.com/crm.rdsre.ca/logo.png" alt="logo" width="150px" />
            </Box>
            <Typography variant="h5" align="center" sx={{ mt: 1, mb: 2 }}>
              <strong>Customer Management</strong>
            </Typography>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                required
                fullWidth
                autoFocus
                name="email"
                label="Email"
                type="email"
                autoComplete="email"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <InputLabel>Password *</InputLabel>
                <OutlinedInput
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  value={password}
                  onChange={handePasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Button fullWidth variant="contained" type="submit">
                Login
              </Button>
            </Box>
          </CardContent>
        </Card>
        <Link
          sx={{ textDecoration: "none", cursor: "pointer" }}
          color="#ffffff"
          align="center"
          variant="body1"
          onClick={handleShowForgotPassword}
        >
          Forgot your password?
        </Link>
        <ForgotPassword open={showForgotPassword} setOpen={setShowForgotPassword} />
      </Box>
    </Box>
  );
};

export default Login;
