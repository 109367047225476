import { useState, useMemo, Dispatch, SetStateAction } from "react";
import { Box, FormControlLabel, Autocomplete, Checkbox, TextField } from "@mui/material";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import AppointmentsDialog from "./AppointmentsDialog";
import { useAppDispatch } from "../../app/hooks";
import { showSuccessSnackbar, showErrorSnackbar } from "../../features/snackbar/snackbarSlice";
import { IAppointment } from "../../types/project";
import StandardTable from "../tables/StandardTable";
import { convertAllDates } from "../../utils/function";
import { IUser } from "../../types/user";
import { selectUser } from "../../features/auth/authSlice";
import { ISchedule } from "../../types/schedule";
import { downloadExcel, downloadPdf, timeZoneDate, camelToNormal, capitalizeFirstLetterEachWord } from "../../utils/function";
import { IRegistrant, IRegistrantStatus } from "../../types/registrant";
import { Link } from "react-router-dom";
import { IQuestion } from "../../types/question";
import { selectProject } from "../../features/project/projectSlice";

const AppointmentsTable = (props: ChildProps) => {
  const user = useSelector(selectUser);
  const {
    appointments,
    loading,
    appointmentsDispatch,
    schedule,
    setSchedule,
    schedules,
    selectedAppointment,
    setSelectedAppointment,
    dialogOpen,
    dialogType,
    setDialogOpen,
    setDialogType,
    setSearchFilter,
    registrant,
    allowSchedule,
    selectedRegistrants,
    setSelectedRegistrants,
  } = props;
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const [salesReps, setSalesReps] = useState<IUser[]>([]);

  useQuery(GETSALESREPS, {
    variables: { filter: { OR: [{ type: "Sales" }, { type: "Manager" }], locked: false } },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setSalesReps(data.userMany);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error getting appointments"));
    },
  });

  const [updateRegistrantStatus] = useMutation(UPDATEREGISTRANT, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [appointmentUpdateById] = useMutation(APPOINTMENTUPDATEBYID, {
    onCompleted: (data) => {},
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error updating Appointment"));
      console.log(err, "err");
    },
  });

  const handleDialog = (appointment: any, type: string) => {
    if (appointment) {
      setSelectedAppointment(appointment);
    } else {
      setSelectedAppointment({
        project: null,
        cancelled: false,
        confirmed: true,
        date: new Date(),
        location: "",
        notes: "",
        purchaserInfo: {
          email: "",
          firstName: "",
          lastName: "",
          numberOfGuests: 0,
          primaryPhone: "",
        },
        questions: [],
        salesNotes: "",
        salesRep: null,
        schedule: null,
        status: "Pending",
        type: "",
        user: user,
      });
    }
    setDialogType(type);
    setDialogOpen(true);
  };

  const columns = useMemo(() => {
    const handleCameIn = (e: any, appointment: IAppointment) => {
      appointmentUpdateById({ variables: { _id: appointment._id, record: { cameIn: !appointment.cameIn } } }).then(async (res) => {
        storeDispatch(showSuccessSnackbar("Appointment Updated"));
        if (res.data.appointmentUpdateById.record.cameIn) {
          if (appointment.registrant) {
            let signedIn = appointment.registrant.statuses.find(
              (status: IRegistrantStatus) =>
                new Date().setHours(0, 0, 0, 0).valueOf() < new Date(status.createdAt!).valueOf() &&
                new Date().setHours(23, 59, 59, 59).valueOf() > new Date(status.createdAt!).valueOf()
            );
            if (!signedIn) {
              await updateRegistrantStatus({
                variables: {
                  email: appointment.registrant.email,
                  project: appointment.registrant.project._id,
                  status: "Came In",
                  rating: appointment.registrant.rating,
                  type: "came in",
                },
              });
            }
          }
        }
        appointmentsDispatch({
          type: "UPDATE",
          payload: { _id: appointment._id, field: "cameIn", value: !appointment.cameIn },
        });
      });
    };

    const handleSalesRepChange = (appointment: IAppointment, salesRep: string) => {
      const newSalesRep = salesReps.find((rep) => rep.fullName === salesRep);

      if (newSalesRep) {
        appointmentUpdateById({ variables: { _id: appointment._id, record: { salesRep: newSalesRep._id } } }).then((res) => {
          if (res.data) {
            appointmentsDispatch({
              type: "UPDATE",
              payload: { _id: appointment._id, field: "salesRep", value: res.data.appointmentUpdateById.record.salesRep },
            });
            storeDispatch(showSuccessSnackbar("Appointment Updated"));
          }
        });
      }
    };
    const selectAll = () => {
      if (selectedRegistrants.length) {
        setSelectedRegistrants([]);
      } else {
        let registrants: any[] = appointments
          .map((appointment: IAppointment) => {
            if (appointment.registrant) {
              return appointment.registrant._id;
            } else return null;
          })
          .filter((id: string | null) => id);

        setSelectedRegistrants(registrants);
      }
    };

    const handleSelectedRegistrants = (id: string) => {
      if (!id) return storeDispatch(showErrorSnackbar("No Registrant Found"));
      if (selectedRegistrants.includes(id)) {
        setSelectedRegistrants(selectedRegistrants.filter((regId: string) => regId !== id));
      } else {
        setSelectedRegistrants([...selectedRegistrants, id]);
      }
    };

    let columnArray: any = [
      {
        Header: "Appointment Date",
        accessor: (rowData: IAppointment) => {
          return (
            <Box sx={{ cursor: "pointer" }} onClick={() => handleDialog(rowData, "details")}>
              <strong>{convertAllDates(timeZoneDate(rowData.date), "PPpp")}</strong>
            </Box>
          );
        },
      },
      {
        Header: "No Response",
        accessor: (rowData: any) => {
          return (
            <FormControlLabel
              onClick={(e) => e.stopPropagation()}
              control={<Checkbox checked={rowData.noResponse} onChange={() => handleDialog(rowData, "noResponse")} />}
              disabled={project && project._id === "65bd404201d843802bb37a51" ? true : false}
              label={""}
            />
          );
        },
      },
      {
        Header: "Confirmed",
        accessor: (rowData: any) => {
          return (
            <FormControlLabel
              onClick={(e) => e.stopPropagation()}
              control={<Checkbox checked={rowData.confirmed} onChange={() => handleDialog(rowData, "confirmed")} />}
              disabled={project && project._id === "65bd404201d843802bb37a51" ? true : false}
              label={""}
            />
          );
        },
      },
      {
        Header: "Cancel",
        accessor: (rowData: any) => {
          return (
            <FormControlLabel
              onClick={(e) => e.stopPropagation()}
              control={<Checkbox checked={rowData.cancelled} onChange={() => handleDialog(rowData, "cancelled")} />}
              disabled={project && project._id === "65bd404201d843802bb37a51" ? true : false}
              label={""}
            />
          );
        },
      },
      {
        Header: "Attended",
        accessor: (rowData: any) => {
          return (
            <FormControlLabel
              onClick={(e) => e.stopPropagation()}
              control={<Checkbox checked={rowData.cameIn} onChange={(e: any) => handleCameIn(e, rowData)} />}
              disabled={project && project._id === "65bd404201d843802bb37a51" ? true : false}
              label={""}
            />
          );
        },
      },
      {
        Header: "Booked By",
        width: 1000,
        accessor: (rowData: any) => (rowData.user ? rowData.user.fullName : "Public"),
      },
      {
        Header: "Sales Rep",
        width: 1000,
        accessor: (rowData: any) => {
          return (
            <Autocomplete
              sx={{ minWidth: "150px" }}
              disableClearable
              options={[...salesReps.map((user: any) => user.fullName), ""]}
              getOptionLabel={(option: any) => option.fullName || option}
              title={"Sales Representative"}
              value={rowData.salesRep ? rowData.salesRep.fullName : ""}
              onChange={(e, value: any) => handleSalesRepChange(rowData, value)}
              renderInput={(params) => <TextField {...params} variant="standard" label="Sales Representative" />}
            />
          );
        },
      },
      {
        Header: "Created Date",
        accessor: (rowData: IAppointment) => convertAllDates(rowData.createdAt, "PPpp"),
      },
      {
        Header: "Notes",
        accessor: (rowData: any) => (
          <Box
            sx={{ display: "-webkit-box", maxWidth: 300, overflow: "hidden", "-webkit-line-clamp": "4", "-webkit-box-orient": "vertical" }}
          >
            {rowData.notes}
          </Box>
        ),
      },
      {
        Header: "RDS Notes",
        accessor: (rowData: any) => (
          <Box
            sx={{ display: "-webkit-box", maxWidth: 300, overflow: "hidden", "-webkit-line-clamp": "4", "-webkit-box-orient": "vertical" }}
          >
            {rowData.salesNotes}
          </Box>
        ),
      },
      {
        Header: "Source",
        accessor: (rowData: any) => (
          <Box
            sx={{ display: "-webkit-box", maxWidth: 300, overflow: "hidden", "-webkit-line-clamp": "4", "-webkit-box-orient": "vertical" }}
          >
            {rowData.registrant ? capitalizeFirstLetterEachWord(rowData.registrant.source) : ""}
          </Box>
        ),
      },
    ];

    if (allowSchedule) {
      columnArray.splice(0, 0, {
        Header: "Schedule",
        accessor: (rowData: IAppointment) => `${rowData.schedule ? rowData.schedule?.name! : "No Event"}`,
      });
    }

    if (setSelectedRegistrants) {
      columnArray.splice(0, 0, {
        id: "selection",
        Header: <Checkbox sx={{ color: "#fff" }} onClick={() => selectAll()} checked={selectedRegistrants.length > 0} />,
        accessor: (rowData: IAppointment) => {
          return (
            <Box>
              <Checkbox
                onChange={(e) => handleSelectedRegistrants(rowData.registrant ? rowData.registrant._id : "")}
                checked={rowData.registrant ? selectedRegistrants.includes(rowData.registrant._id) : false}
              />
            </Box>
          );
        },
      });
    }

    if (!registrant) {
      columnArray.splice(1, 0, {
        Header: "Project",
        accessor: (rowData: IAppointment) => `${rowData.project?.name}`,
      });
      columnArray.splice(2, 0, {
        Header: "Client Phone",
        accessor: (rowData: IAppointment) => `${rowData.purchaserInfo.primaryPhone}`,
      });
      columnArray.splice(8, 0, {
        Header: "Client Email",
        accessor: (rowData: IAppointment) => `${rowData.purchaserInfo.email}`,
      });
      columnArray.splice(9, 0, {
        Header: "Type",
        accessor: (rowData: IAppointment) => {
          if (rowData.registrant) {
            return camelToNormal(rowData.registrant.realtorType);
          } else {
            return "";
          }
        },
      });
      columnArray.splice(2, 0, {
        Header: "Client",
        accessor: (rowData: IAppointment) => {
          if (rowData.registrant) {
            return (
              <Link
                target="_blank"
                style={{ textDecoration: "none", color: "#000" }}
                to={`/dashboard/project/${rowData?.project?._id}/registrant/${rowData.registrant._id}`}
              >
                <strong>
                  {rowData.purchaserInfo.firstName} {rowData.purchaserInfo.lastName}
                </strong>
              </Link>
            );
          } else {
            return (
              <Box>
                {rowData.purchaserInfo.firstName} {rowData.purchaserInfo.lastName}
              </Box>
            );
          }
        },
      });
    }

    if (project && project?._id === "65bd404201d843802bb37a51") {
      columnArray.splice(0, 1);
      columnArray.splice(2, 3);
      columnArray.splice(4, 2);
      columnArray.splice(5, 1);

      columnArray.splice(2, 0, {
        Header: "Location",
        accessor: (rowData: IAppointment) => rowData.location,
      });
    }

    return columnArray;
  }, [salesReps, appointments, selectedRegistrants, appointmentUpdateById, registrant, appointmentsDispatch, storeDispatch]);

  const download = (type: string, data: any) => {
    let unitColumns = [
      {
        label: "Date",
        id: "date",
      },
      {
        label: "Location",
        id: "location",
      },
      {
        label: "Created At",
        id: "createdAt",
      },
      {
        label: "Type",
        id: "type",
      },
      {
        label: "Source",
        id: "source",
      },
      {
        label: "User",
        id: "realtor",
      },
      {
        label: "Purchaser First Name",
        id: "purchaserFirstName",
      },
      {
        label: "Purchaser Last Name",
        id: "purchaserLastName",
      },
      {
        label: "Purchaser Email",
        id: "purchaserEmail",
      },
      {
        label: "Purchaser Phone",
        id: "purchaserPhone",
      },
      {
        label: "Number of Guests",
        id: "purchaserGuests",
      },
      {
        label: "Notes",
        id: "notes",
      },
      {
        label: "RDS Notes",
        id: "salesNotes",
      },
      {
        label: "SalesRep",
        id: "salesRep",
      },
      {
        label: "Confirmed",
        id: "confirmed",
      },
      {
        label: "Cancel",
        id: "cancelled",
      },
      {
        label: "No Response",
        id: "noResponse",
      },
      {
        label: "Attended",
        id: "cameIn",
      },
    ];

    let widths: any = {
      date: 15,
      location: 15,
      createdAt: 15,
      type: 15,
      realtor: 15,
      purchaserFirstName: 15,
      purchaserLastName: 15,
      purchaserEmail: 15,
      purchaserPhone: 15,
      purchaserGuests: 15,
      notes: 15,
      salesNotes: 15,
      salesRep: 15,
      source: 15,
      confirmed: 15,
      cancelled: 15,
      noResponse: 15,
      cameIn: 15,
    };

    let pdfWidths: any = {
      date: 200,
      location: 200,
      createdAt: 200,
      type: 200,
      realtor: 200,
      purchaserFirstName: 200,
      purchaserLastName: 200,
      purchaserEmail: 200,
      notes: 200,
      salesNotes: 200,
      purchaserPhone: 200,
      purchaserGuests: 200,
      salesRep: 200,
      source: 200,
      confirmed: 200,
      cancelled: 200,
      noResponse: 200,
      cameIn: 200,
    };

    let sheetTitle = "Appointments";

    if (schedule) {
      sheetTitle = `${schedule.project.name} - ${convertAllDates(schedule.schedules[0].date, "PP")} Appointments`;
    }

    let maximumQuestion: IQuestion[] = [];

    for (const appointment of appointments) {
      if (appointment.questions && appointment.questions.length > maximumQuestion.length) {
        maximumQuestion = appointment.questions;
      }
    }

    let questionColumns = maximumQuestion.map((question: any) => {
      return {
        label: question.questionId.question,
        id: question.questionId._id,
      };
    });

    unitColumns = [...unitColumns, ...questionColumns];

    if (maximumQuestion.length) {
      for (const question of questionColumns) {
        widths[question.id] = 15;
        pdfWidths[question.id] = 200;
      }
    }

    let allData = appointments.map((data: any) => {
      let appointmentObject: any = {
        date: `${convertAllDates(timeZoneDate(data.date), "PPpp")}`,
        location: data.location,
        createdAt: data.createdAt ? `${convertAllDates(data.createdAt, "PPpp")}` : "-",
        realtor: data.user ? `${data.user.fullName} (${data.user.email})` : "Public",
        type: data.registrant && data.registrant.realtorType ? camelToNormal(data.registrant.realtorType) : "",
        source: data.registrant && data.registrant.source ? capitalizeFirstLetterEachWord(data.registrant.source) : "",
        purchaserFirstName: `${data.purchaserInfo.firstName}`,
        purchaserLastName: `${data.purchaserInfo.lastName}`,
        purchaserEmail: `${data.purchaserInfo.email}`,
        purchaserPhone: `${data.purchaserInfo.primaryPhone}`,
        purchaserGuests: `${data.purchaserInfo.numberOfGuests}`,
        notes: data.notes,
        salesNotes: data.salesNotes,
        salesRep: data.salesRep ? `${data.salesRep.fullName}` : "N/A",
        confirmed: data.confirmed ? "Confirmed" : "Not Confirmed",
        cancelled: data.cancelled ? "Cancelled" : "Not Cancelled",
        noResponse: data.noResponse ? "No Response" : "",
        cameIn: data.cameIn ? "Yes" : "No",
      };

      if (questionColumns.length) {
        for (const question of questionColumns) {
          let selectedQuestion = data.questions.find(
            (appointmentQuestion: any) => appointmentQuestion.questionId._id.toString() === question.id.toString()
          );
          if (selectedQuestion) {
            appointmentObject[selectedQuestion.questionId._id] = selectedQuestion.answer.join(", ");
          }
        }
      }

      return appointmentObject;
    });

    if (type === "excel") {
      downloadExcel([allData], [unitColumns], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([allData], [unitColumns], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const handleGlobalFilterValue = (value: string) => {
    if (setSearchFilter) {
      setSearchFilter(value);
    }
  };

  return (
    <div>
      <AppointmentsDialog
        schedule={schedule}
        setSchedule={setSchedule}
        appointmentsDispatch={appointmentsDispatch}
        selectedAppointment={selectedAppointment}
        setSelectedAppointment={setSelectedAppointment}
        setDialogType={setDialogType}
        setDialogOpen={setDialogOpen}
        dialogType={dialogType}
        dialogOpen={dialogOpen}
        schedules={schedules}
      />
      <Box sx={{ mt: 2 }}>
        <StandardTable
          count={appointments.length}
          handleGlobalFilterValue={handleGlobalFilterValue}
          download={download}
          loading={loading}
          data={appointments}
          columns={columns}
          user={user}
        />
      </Box>
    </div>
  );
};

interface ChildProps {
  appointments: IAppointment[];
  schedule: ISchedule | null;
  loading: boolean;
  setSchedule: any;
  appointmentsDispatch: any;
  selectedAppointment: any;
  setSelectedAppointment: any;
  dialogType: string;
  dialogOpen: boolean;
  setDialogType: Dispatch<SetStateAction<string>>;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  schedules: ISchedule[];
  setSearchFilter?: Dispatch<SetStateAction<string>>;
  registrant?: IRegistrant;
  allowSchedule?: boolean;
  selectedRegistrants: string[];
  setSelectedRegistrants: any;
}

const GETSALESREPS = gql`
  query userMany($filter: FilterFindManyUserInput) {
    userMany(filter: $filter, limit: 10000) {
      _id
      firstName
      lastName
      fullName
      realtor {
        _id
      }
      locked
    }
  }
`;

const APPOINTMENTUPDATEBYID = gql`
  mutation appointmentUpdateById($_id: MongoID!, $record: UpdateByIdAppointmentInput!) {
    appointmentUpdateById(_id: $_id, record: $record) {
      recordId
      record {
        _id
        user {
          fullName
          email
          realtor {
            _id
            fullName
            directPhone
          }
        }
        date
        location
        purchaserInfo {
          firstName
          lastName
          email
          primaryPhone
          numberOfGuests
        }
        questions {
          questionId {
            _id
            name
            question
            type
            choices {
              choice
              followUp {
                _id
              }
            }
          }
          answer
        }
        status
        type
        notes
        confirmed
        cancelled
        noResponse
        cameIn
        salesNotes
        salesRep {
          _id
          fullName
        }
        createdAt
      }
    }
  }
`;

const UPDATEREGISTRANT = gql`
  mutation updateRegistrantStatus($email: String!, $project: MongoID!, $status: String!, $rating: String!, $type: String!) {
    updateRegistrantStatus(email: $email, project: $project, status: $status, rating: $rating, type: $type) {
      _id
    }
  }
`;

export default AppointmentsTable;
