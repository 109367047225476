import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { convertAllDates } from "../../../utils/function";

const MailingStats = (props: ChildProps) => {
  const { mailing } = props;
  const navigate = useNavigate();
  const { projectid } = useParams();

  const handleThresholds = (value: number, type: string) => {
    if (type === "unopened") {
      if (value < 30) return "#c7f5c7";
      else if (value < 50) return "#fdfdbe";
      else return "#f5cccc";
    }
    if (type === "clicked") {
      if (value > 30) return "#c7f5c7";
      else if (value > 10) return "#fdfdbe";
      else return "#f5cccc";
    }
    if (type === "opened") {
      if (value > 40) return "#c7f5c7";
      else if (value > 20) return "#fdfdbe";
      else return "#f5cccc";
    }
    if (type === "transientBounce" || type === "permanentBounce") {
      if (value < 0.01) return "#c7f5c7";
      else if (value < 0.05) return "#fdfdbe";
      else return "#f5cccc";
    }
    if (type === "complaints") {
      if (value < 0.005) return "#c7f5c7";
      else if (value < 0.01) return "#fdfdbe";
      else return "#f5cccc";
    }
    return "#fff";
  };

  const massMails = [
    {
      title: "Unopened",
      value: mailing.sent,
      percentage:
        (mailing.sent /
          (mailing.sent +
            (mailing.clicked ? mailing.clicked : 0) +
            (mailing.opened ? mailing.opened : 0) +
            (mailing.permanentBounce ? mailing.permanentBounce : 0) +
            (mailing.transientBounce ? mailing.transientBounce : 0) +
            (mailing.complaints ? mailing.complaints : 0))) *
        100,
      bgColor: handleThresholds(
        ((mailing.sent -
          (mailing.clicked ? mailing.clicked : 0) -
          (mailing.opened ? mailing.opened : 0) -
          (mailing.permanentBounce ? mailing.permanentBounce : 0) -
          (mailing.transientBounce ? mailing.transientBounce : 0) -
          (mailing.complaints ? mailing.complaints : 0)) /
          mailing.sent) *
          100,
        "unopened"
      ),
    },
    {
      title: "Opened",
      value: mailing.opened ? mailing.opened : 0,
      percentage:
        ((mailing.opened ? mailing.opened : 0) /
          (mailing.sent +
            (mailing.clicked ? mailing.clicked : 0) +
            (mailing.opened ? mailing.opened : 0) +
            (mailing.permanentBounce ? mailing.permanentBounce : 0) +
            (mailing.transientBounce ? mailing.transientBounce : 0) +
            (mailing.complaints ? mailing.complaints : 0))) *
        100,
      bgColor: handleThresholds(((mailing.opened ? mailing.opened : 0) / mailing.sent) * 100, "opened"),
    },
    {
      title: "Clicked",
      value: mailing.clicked ? mailing.clicked : 0,
      percentage:
        ((mailing.clicked ? mailing.clicked : 0) /
          (mailing.sent +
            (mailing.clicked ? mailing.clicked : 0) +
            (mailing.opened ? mailing.opened : 0) +
            (mailing.permanentBounce ? mailing.permanentBounce : 0) +
            (mailing.transientBounce ? mailing.transientBounce : 0) +
            (mailing.complaints ? mailing.complaints : 0))) *
        100,
      bgColor: handleThresholds(((mailing.clicked ? mailing.clicked : 0) / mailing.sent) * 100, "clicked"),
    },
    {
      title: "Transient Bounce",
      value: mailing.transientBounce ? mailing.transientBounce : 0,
      percentage:
        ((mailing.transientBounce ? mailing.transientBounce : 0) /
          (mailing.sent +
            (mailing.clicked ? mailing.clicked : 0) +
            (mailing.opened ? mailing.opened : 0) +
            (mailing.permanentBounce ? mailing.permanentBounce : 0) +
            (mailing.transientBounce ? mailing.transientBounce : 0) +
            (mailing.complaints ? mailing.complaints : 0))) *
        100,
      bgColor: handleThresholds(((mailing.transientBounce ? mailing.transientBounce : 0) / mailing.sent) * 100, "transientBounce"),
    },
    {
      title: "Permanent Bounce",
      value: mailing.permanentBounce ? mailing.permanentBounce : 0,
      percentage:
        ((mailing.permanentBounce ? mailing.permanentBounce : 0) /
          (mailing.sent +
            (mailing.clicked ? mailing.clicked : 0) +
            (mailing.opened ? mailing.opened : 0) +
            (mailing.permanentBounce ? mailing.permanentBounce : 0) +
            (mailing.transientBounce ? mailing.transientBounce : 0) +
            (mailing.complaints ? mailing.complaints : 0))) *
        100,
      bgColor: handleThresholds(((mailing.permanentBounce ? mailing.permanentBounce : 0) / mailing.sent) * 100, "permanentBounce"),
    },
    {
      title: "Complaints",
      value: mailing.complaints ? mailing.complaints : 0,
      percentage:
        ((mailing.complaints ? mailing.complaints : 0) /
          (mailing.sent +
            (mailing.clicked ? mailing.clicked : 0) +
            (mailing.opened ? mailing.opened : 0) +
            (mailing.permanentBounce ? mailing.permanentBounce : 0) +
            (mailing.transientBounce ? mailing.transientBounce : 0) +
            (mailing.complaints ? mailing.complaints : 0))) *
        100,
      bgColor: handleThresholds(
        ((mailing.complaints ? mailing.complaints : 0) /
          (mailing.sent +
            (mailing.clicked ? mailing.clicked : 0) +
            (mailing.opened ? mailing.opened : 0) +
            (mailing.permanentBounce ? mailing.permanentBounce : 0) +
            (mailing.transientBounce ? mailing.transientBounce : 0) +
            (mailing.complaints ? mailing.complaints : 0))) *
          100,
        "complaints"
      ),
    },
  ];

  return (
    <Box sx={{ p: 2 }}>
      <Typography sx={{ mb: 0, textAlign: "center" }} variant="h3" component="div" gutterBottom>
        <strong>{mailing.mailSchedule.emailTemplate.subject}</strong>
      </Typography>
      <Box sx={{ textAlign: "center", mb: 1 }}>
        <Box>
          <em style={{ fontSize: "11px" }}>{convertAllDates(mailing.mailSchedule.mailDate, "PPpp")}</em>
        </Box>
        <Box>
          <em style={{ fontSize: "11px" }}>
            Total:{" "}
            {mailing.sent +
              (mailing.clicked ? mailing.clicked : 0) +
              (mailing.opened ? mailing.opened : 0) +
              (mailing.permanentBounce ? mailing.permanentBounce : 0) +
              (mailing.transientBounce ? mailing.transientBounce : 0) +
              (mailing.complaints ? mailing.complaints : 0)}
          </em>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {massMails.map((mail, index) => {
          return (
            <Grid
              key={index}
              item
              xs={12}
              sm={4}
              onClick={() =>
                mail.value
                  ? navigate(`/dashboard/project/${projectid}/mail/${mailing.mailSchedule._id}?row=15&page=1&type=${mail.title}`)
                  : null
              }
            >
              <Box
                sx={{
                  fontSize: "1rem",
                  padding: "0.5rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #000",
                  backgroundColor: mail.bgColor,
                  cursor: mail.value ? "pointer" : "auto",
                  height: "100%",
                }}
              >
                <Box sx={{ fontSize: "24px" }}>{mail.percentage.toFixed(2)}%</Box>
                <Box sx={{ fontSize: "10px" }}>
                  {mail.title} ({mail.value})
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

interface ChildProps {
  mailing: any;
}
export default MailingStats;
